import playstation from '../../../assets/images/collection/platforms_logos/playstation.png'
import playstation2 from '../../../assets/images/collection/platforms_logos/playstation_2.png'
import psp from '../../../assets/images/collection/platforms_logos/psp.png'
import playstation3 from '../../../assets/images/collection/platforms_logos/playstation_3.png'
import playstation4 from '../../../assets/images/collection/platforms_logos/playstation_4.png'
import playstation5 from '../../../assets/images/collection/platforms_logos/playstation_5.png'
import famicomdisksystem from '../../../assets/images/collection/platforms_logos/famicom_disk_system.png'
import nes from '../../../assets/images/collection/platforms_logos/nes.png'
import gameboy from '../../../assets/images/collection/platforms_logos/game_boy.png'
import supernintendo from '../../../assets/images/collection/platforms_logos/super_nintendo.png'
import gameboycolor from '../../../assets/images/collection/platforms_logos/game_boy_color.png'
import nintendo64 from '../../../assets/images/collection/platforms_logos/nintendo_64.png'
import gameboyadvance from '../../../assets/images/collection/platforms_logos/game_boy_advance.png'
import gamecube from '../../../assets/images/collection/platforms_logos/gamecube.png'
import nintendods from '../../../assets/images/collection/platforms_logos/nintendo_ds.png'
import wii from '../../../assets/images/collection/platforms_logos/wii.png'
import nintendo3ds from '../../../assets/images/collection/platforms_logos/nintendo_3ds.png'
import wiiu from '../../../assets/images/collection/platforms_logos/wii_u.png'
import nintendoswitch from '../../../assets/images/collection/platforms_logos/nintendo_switch.png'
import xbox from '../../../assets/images/collection/platforms_logos/xbox.png'
import xbox360 from '../../../assets/images/collection/platforms_logos/xbox_360.png'
import mastersystem from '../../../assets/images/collection/platforms_logos/master_system.png'
import megadrive from '../../../assets/images/collection/platforms_logos/megadrive.png'
import dreamcast from '../../../assets/images/collection/platforms_logos/dreamcast.png'
import atari2600 from '../../../assets/images/collection/platforms_logos/atari_2600.png'
import pcengine from '../../../assets/images/collection/platforms_logos/pc_engine.png'
import pcenginesupercd from '../../../assets/images/collection/platforms_logos/pc_engine_super_cd.png'
import amiga from '../../../assets/images/collection/platforms_logos/amiga.png'
import amstradcpc from '../../../assets/images/collection/platforms_logos/amstrad_cpc.png'
import msx2 from '../../../assets/images/collection/platforms_logos/msx2.png'
import neogeo from '../../../assets/images/collection/platforms_logos/neo_geo.png'
import vectrex from '../../../assets/images/collection/platforms_logos/vectrex.png'
import arcade from '../../../assets/images/collection/platforms_logos/arcade.png'
import pc from '../../../assets/images/collection/platforms_logos/pc.png'
import mac from '../../../assets/images/collection/platforms_logos/mac.png'
import appleii from '../../../assets/images/collection/platforms_logos/apple_ii.png'
import ios from '../../../assets/images/collection/platforms_logos/ios.png'

const images = {
  PlayStation: playstation,
  'PlayStation 2': playstation2,
  PSP: psp,
  'PlayStation 3': playstation3,
  'PlayStation 4': playstation4,
  'PlayStation 5': playstation5,
  'Famicom Disk System': famicomdisksystem,
  NES: nes,
  'Game Boy': gameboy,
  'Super Nintendo': supernintendo,
  'Game Boy Color': gameboycolor,
  'Nintendo 64': nintendo64,
  'Game Boy Advance': gameboyadvance,
  Gamecube: gamecube,
  'Nintendo DS': nintendods,
  Wii: wii,
  'Wii U': wiiu,
  'Nintendo 3DS': nintendo3ds,
  'Nintendo Switch': nintendoswitch,
  Xbox: xbox,
  'Xbox 360': xbox360,
  'Master System': mastersystem,
  Megadrive: megadrive,
  Dreamcast: dreamcast,
  'Atari 2600': atari2600,
  'PC Engine': pcengine,
  'PC Engine Super CD': pcenginesupercd,
  Amiga: amiga,
  'Amstrad CPC': amstradcpc,
  MSX2: msx2,
  'Neo Geo': neogeo,
  Vectrex: vectrex,
  Arcade: arcade,
  PC: pc,
  Mac: mac,
  'Apple II': appleii,
  iOS: ios,
}

export default images
