function CollectionContentPages({ totalPages, currentPage, onPageChange }) {
  // Fonction pour générer les pages à afficher
  const generatePageRange = () => {
    const pages = []
    if (totalPages <= 8) {
      // Afficher toutes les pages si le total est <= 8
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i)
      }
    } else {
      // Déterminer la plage de pages autour de la page actuelle
      const start = Math.max(1, currentPage - 2)
      const end = Math.min(totalPages, currentPage + 2)

      if (start > 1) {
        pages.push(1)
        if (start > 2) pages.push('...')
      }

      for (let i = start; i <= end; i++) {
        pages.push(i)
      }

      if (end < totalPages) {
        if (end < totalPages - 1) pages.push('...')
        pages.push(totalPages)
      }
    }
    return pages
  }

  const handlePageClick = (page) => {
    if (page === '...' || page === currentPage) return
    onPageChange(page)
  }

  const pageRange = generatePageRange() // Utilisation du nom correct

  return (
    <div className="pagination-container">
      {pageRange.map((page, index) => {
        // Détermine si le bouton est le premier ou le dernier
        const isFirst = index === 0
        const isLast = index === pageRange.length - 1

        return (
          <button
            key={index}
            className={`page-button ${page === currentPage ? 'active' : ''} ${
              page === '...' ? 'ellipsis' : ''
            } ${isFirst ? 'first' : isLast ? 'last' : ''}`}
            onClick={() => handlePageClick(page)}
            disabled={page === '...'}
          >
            {page}
          </button>
        )
      })}
    </div>
  )
}

export default CollectionContentPages
