import { useState } from 'react'
import AnimateHeight from 'react-animate-height'
import empty_image from '../assets/images/empty_image.png' // invisible img useful to set screenshotReady to true on load
// Adapt the names and the code if number of proto become larger than 10 in the future
import Protogame_0 from './protogames-content/Protogame_0'
import Protogame_1 from './protogames-content/Protogame_1'
import Protogame_2 from './protogames-content/Protogame_2'
import Protogame_3 from './protogames-content/Protogame_3'
import Protogame_4 from './protogames-content/Protogame_4'
import Protogame_5 from './protogames-content/Protogame_5'
import Protogame_6 from './protogames-content/Protogame_6'
import Protogame_7 from './protogames-content/Protogame_7'
import Protogame_8 from './protogames-content/Protogame_8'
import Protogame_9 from './protogames-content/Protogame_9'

// Effective but not very elegant solution.
function Protogame(props) {
  const [pageJustLoaded, setPageJustLoaded] = useState(true)
  const [prodDivHeight, setProdDivHeight] = useState(0)
  const [realSelected, setRealSelected] = useState(0)

  // useful to already adapt the div at the first arrival on the page
  if (pageJustLoaded) {
    setProdDivHeight('auto')
    setPageJustLoaded(false)
    setRealSelected(props.selected)
  } else if (prodDivHeight !== 0 && realSelected != props.selected) {
    setProdDivHeight(0)
  }

  // called when the last screnshot is loaded. adapt the div the it real size
  function trueDivAdapter() {
    var divRealHeight = document.getElementById('protogame').offsetHeight
    adaptToNewContent(divRealHeight)
  }

  // called when animation setting div height to 0 is finished
  function adaptToNewContent(realSize) {
    setRealSelected(props.selected)
    if (realSize != null) {
      if (typeof realSize == 'number') setProdDivHeight(realSize)
      else setProdDivHeight('auto')
      props.setProgressBar(props.adaptProgressBar)
    } else setProdDivHeight('auto')
  }

  // display the selected prototype (realSelected change only when animation height is at 0)
  switch (realSelected) {
    case 0:
      return (
        <AnimateHeight
          duration={500}
          height={prodDivHeight}
          delay={0}
          easing={'linear'}
          onAnimationStart={props.initAdaptedScroll}
          onAnimationEnd={adaptToNewContent}
        >
          <div id="protogame">
            <Protogame_0 />
            <img
              id="empty-img"
              src={empty_image}
              onLoad={() => {
                adaptToNewContent()
              }}
            />
          </div>
        </AnimateHeight>
      )
    case 1:
      return (
        <AnimateHeight
          duration={500}
          height={prodDivHeight}
          delay={0}
          easing={'linear'}
          onAnimationStart={props.initAdaptedScroll}
          onAnimationEnd={adaptToNewContent}
        >
          <div id="protogame">
            <Protogame_1
              ondisplay={props.ondisplay}
              trueDivAdapter={trueDivAdapter}
            />
          </div>
        </AnimateHeight>
      )
    case 2:
      return (
        <AnimateHeight
          duration={500}
          height={prodDivHeight}
          delay={0}
          easing={'linear'}
          onAnimationStart={props.initAdaptedScroll}
          onAnimationEnd={adaptToNewContent}
        >
          <div id="protogame">
            <Protogame_2
              ondisplay={props.ondisplay}
              trueDivAdapter={trueDivAdapter}
            />
          </div>
        </AnimateHeight>
      )
    case 3:
      return (
        <AnimateHeight
          duration={500}
          height={prodDivHeight}
          delay={0}
          easing={'linear'}
          onAnimationStart={props.initAdaptedScroll}
          onAnimationEnd={adaptToNewContent}
        >
          <div id="protogame">
            <Protogame_3
              ondisplay={props.ondisplay}
              trueDivAdapter={trueDivAdapter}
            />
          </div>
        </AnimateHeight>
      )
    case 4:
      return (
        <AnimateHeight
          duration={500}
          height={prodDivHeight}
          delay={0}
          easing={'linear'}
          onAnimationStart={props.initAdaptedScroll}
          onAnimationEnd={adaptToNewContent}
        >
          <div id="protogame">
            <Protogame_4
              ondisplay={props.ondisplay}
              trueDivAdapter={trueDivAdapter}
            />
          </div>
        </AnimateHeight>
      )
    case 5:
      return (
        <AnimateHeight
          duration={500}
          height={prodDivHeight}
          delay={0}
          easing={'linear'}
          onAnimationStart={props.initAdaptedScroll}
          onAnimationEnd={adaptToNewContent}
        >
          <div id="protogame">
            <Protogame_5
              ondisplay={props.ondisplay}
              displayPG={props.displayPG}
              trueDivAdapter={trueDivAdapter}
            />
          </div>
        </AnimateHeight>
      )
    case 6:
      return (
        <AnimateHeight
          duration={500}
          height={prodDivHeight}
          delay={0}
          easing={'linear'}
          onAnimationStart={props.initAdaptedScroll}
          onAnimationEnd={adaptToNewContent}
        >
          <div id="protogame">
            <Protogame_6
              ondisplay={props.ondisplay}
              trueDivAdapter={trueDivAdapter}
            />
          </div>
        </AnimateHeight>
      )
    case 7:
      return (
        <AnimateHeight
          duration={500}
          height={prodDivHeight}
          delay={0}
          easing={'linear'}
          onAnimationStart={props.initAdaptedScroll}
          onAnimationEnd={adaptToNewContent}
        >
          <div id="protogame">
            <Protogame_7
              ondisplay={props.ondisplay}
              trueDivAdapter={trueDivAdapter}
            />
          </div>
        </AnimateHeight>
      )
    case 8:
      return (
        <AnimateHeight
          duration={500}
          height={prodDivHeight}
          delay={0}
          easing={'linear'}
          onAnimationStart={props.initAdaptedScroll}
          onAnimationEnd={adaptToNewContent}
        >
          <div id="protogame">
            <Protogame_8
              ondisplay={props.ondisplay}
              displayPG={props.displayPG}
              trueDivAdapter={trueDivAdapter}
            />
          </div>
        </AnimateHeight>
      )
    case 9:
      return (
        <AnimateHeight
          duration={500}
          height={prodDivHeight}
          delay={0}
          easing={'linear'}
          onAnimationStart={props.initAdaptedScroll}
          onAnimationEnd={adaptToNewContent}
        >
          <div id="protogame">
            <Protogame_9
              ondisplay={props.ondisplay}
              displayPG={props.displayPG}
              trueDivAdapter={trueDivAdapter}
            />
          </div>
        </AnimateHeight>
      )
    default:
      return (
        <AnimateHeight
          duration={500}
          height={prodDivHeight}
          delay={0}
          easing={'linear'}
          onAnimationStart={props.initAdaptedScroll}
          onAnimationEnd={adaptToNewContent}
        >
          <div id="protogame">
            <Protogame_0 />
            <img
              id="empty-img"
              src={empty_image}
              onLoad={() => {
                trueDivAdapter()
              }}
            />
          </div>
        </AnimateHeight>
      )
  }
}

export default Protogame
