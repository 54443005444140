// This is the all prods category part, including websites and game prototypes.
// the big argument is useful to know what size (ten or twelves columns) the category takes.
function ProdCategory({ children, big }) {
  return (
    <div className={`prod-category ${big ? 'twelves columns' : 'two columns'}`}>
      <h2 className="categorie-h2"> {children} </h2>
      <br />
    </div>
  )
}

export default ProdCategory
