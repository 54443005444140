import { useState, useCallback } from 'react'
import ProtoScreenshots from '../ProtoScreenshots'
import CantPlay from '../CantPlay'
import CantPlayTemp from '../CantPlayTemp'
import SkillImg from '../SkillImg'
import processing from '../../assets/images/skills/processing.svg'
import js from '../../assets/images/skills/js.svg'
import processing_uncolored from '../../assets/images/skills/processing-uncolored.svg'
import js_uncolored from '../../assets/images/skills/js-uncolored.svg'
import screenshot_0 from '../../assets/images/screenshots/protogame/acolor/acolor_0.png'
import screenshot_1 from '../../assets/images/screenshots/protogame/acolor/acolor_1.png'
import screenshot_2 from '../../assets/images/screenshots/protogame/acolor/acolor_2.png'
import screenshot_3 from '../../assets/images/screenshots/protogame/acolor/acolor_3.png'

function Protogame_6(props) {
  const [skillMouseIsOn, setSkillMouseIsOn] = useState(null)
  const screenshots = [screenshot_0, screenshot_1, screenshot_2, screenshot_3]

  const [/* proto, */ setProto] = useState(
    <button id="play-button" onClick={launchProto}>
      Lancer le prototype
    </button>
  )

  function launchProto() {
    setProto(protoToLoad)
  }

  const protoToLoad = (
    <div>
      {/*
      <div id="anothercolorgame" className="game" tabIndex="1">
        <iframe
          src="/Protogames/Protogame-6/anothercolorgame.html"
          title="another color thing"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          scrolling="no"
        ></iframe>
      </div>
      */}
      <CantPlay />
    </div>
  )

  const trueDivAdapter = useCallback(() => {
    props.trueDivAdapter()
  }, [props])

  return (
    <div>
      {/*proto*/}
      <CantPlayTemp />
      <br />
      <ProtoScreenshots
        screenshots={screenshots}
        screenRowName="acolor"
        trueDivAdapter={trueDivAdapter}
      />
      <br />
      <p>
        <span className="important">Niveau unique</span> dans celui-ci, le but
        étant de m&apos;entrainer sur le langage Processing.
      </p>
      <div className="controls">
        <span className="important">CONTROLES</span> : <br />- Touche{' '}
        <span className="important">Espace</span> pour commencer
        <br />- Le reste des contrôles est expliqué en jeu
      </div>
      <br />
      <span className="important">Textes en anglais</span>
      <br />
      <br />
      {skillMouseIsOn === 'processing' ? (
        <SkillImg
          skillsrc={processing}
          skilltitle="Ce prototype a initialement été codé en Processing"
          skillname="processing"
          onmouseleave={() => setSkillMouseIsOn(null)}
        />
      ) : (
        <SkillImg
          skillsrc={processing_uncolored}
          skilltitle="Ce prototype a initialement été codé en Processing"
          skillname="processing"
          onmouseenter={() => setSkillMouseIsOn('processing')}
        />
      )}

      {skillMouseIsOn === 'js' ? (
        <SkillImg
          skillsrc={js}
          skilltitle="Ce prototype a été porté sur navigateur avec Processing.js"
          skillname="js"
          onmouseleave={() => setSkillMouseIsOn(null)}
        />
      ) : (
        <SkillImg
          skillsrc={js_uncolored}
          skilltitle="Ce prototype a été porté sur navigateur avec Processing.js"
          skillname="js"
          onmouseenter={() => setSkillMouseIsOn('js')}
        />
      )}
    </div>
  )
}

export default Protogame_6
