import { useState, useEffect } from 'react'
// import { Unity, UnityContent } from 'react-unity-webgl'
import CantPlay from '../CantPlay'
import CantPlayTemp from '../CantPlayTemp'
import SkillImg from '../SkillImg'
import ProtoScreenshots from '../ProtoScreenshots'
import unity from '../../assets/images/skills/unity.svg'
import csharp from '../../assets/images/skills/csharp.svg'
import unity_uncolored from '../../assets/images/skills/unity-uncolored.svg'
import csharp_uncolored from '../../assets/images/skills/csharp-uncolored.svg'
import screenshot_0 from '../../assets/images/screenshots/protogame/door/door_0.png'
import screenshot_1 from '../../assets/images/screenshots/protogame/door/door_1.png'
import screenshot_2 from '../../assets/images/screenshots/protogame/door/door_2.png'
import screenshot_3 from '../../assets/images/screenshots/protogame/door/door_3.png'

function Protogame_1(props) {
  const [skillMouseIsOn, setSkillMouseIsOn] = useState(null)
  const [/* proto, */ setProto] = useState(
    <button id="play-button" onClick={() => launchProto()}>
      Lancer le prototype
    </button>
  )

  const screenshots = [screenshot_0, screenshot_1, screenshot_2, screenshot_3]

  function launchProto() {
    setProto(protoToLoad)
  }

  /*
  const unityContent = new UnityContent(
    '/Protogames/Protogame-1/Build/Build.json',
    '/Protogames/Protogame-1/Build/UnityLoader.js'
  )
  */

  const protoToLoad = (
    <div>
      <div id="unityContainer" className="game">
        {/* <Unity unityContent={unityContent} /> */}
      </div>
      <CantPlay />
    </div>
  )

  function trueDivAdapter() {
    if (props.trueDivAdapter) {
      props.trueDivAdapter()
    }
  }

  // Utilisation de useEffect pour gérer les effets secondaires
  useEffect(() => {
    if (props.ondisplay) {
      props.ondisplay()
    }
  }, [props.ondisplay])

  return (
    <div>
      {/*proto*/}
      <CantPlayTemp />
      <br />
      <ProtoScreenshots
        screenshots={screenshots}
        screenRowName="unity"
        trueDivAdapter={trueDivAdapter}
      />
      <br />
      <p>
        Prototype de jeu expérimental{' '}
        <span className="important">ultra minimaliste</span> créé en peu de
        temps.
        <br />
        Les règles sont volontairement obscures.
      </p>
      <div className="controls">
        <span className="important">CONTROLES</span> : <br />-
        <span className="important">WASD</span> (passer le clavier en QWERTY) ou{' '}
        <span className="important">touches directionnelles</span> pour se
        déplacer
        <br />-<span className="important">Souris</span> pour contrôler la
        caméra (aucun clic n&apos;est recquis)
      </div>
      <br />
      {skillMouseIsOn === 'unity' ? (
        <SkillImg
          skillsrc={unity}
          skilltitle="Ce prototype a été créé sous Unity"
          skillname="unity"
          onmouseleave={() => setSkillMouseIsOn(null)}
        />
      ) : (
        <SkillImg
          skillsrc={unity_uncolored}
          skilltitle="Ce prototype a été créé sous Unity"
          skillname="unity"
          onmouseenter={() => setSkillMouseIsOn('unity')}
        />
      )}
      {skillMouseIsOn === 'csharp' ? (
        <SkillImg
          skillsrc={csharp}
          skilltitle="Les scripts ont été écrits en C#"
          skillname="csharp"
          onmouseleave={() => setSkillMouseIsOn(null)}
        />
      ) : (
        <SkillImg
          skillsrc={csharp_uncolored}
          skilltitle="Les scripts ont été écrits en C#"
          skillname="csharp"
          onmouseenter={() => setSkillMouseIsOn('csharp')}
        />
      )}
    </div>
  )
}

export default Protogame_1
