import style from '../styles/Header.module.css'
import profil from '../assets/images/profil.png'

function Header() {
  function refreshPage() {
    window.location.reload(false)
  }

  return (
    <div id="header">
      <div className="row">
        <div className="twelve columns">
          <img
            className={style.profiImg}
            src={profil}
            alt="Nicolas Goberville"
            draggable="false"
            onClick={refreshPage}
          />
        </div>
      </div>
      <div className="row">
        <div className="twelve columns">
          <script src="js/no-drag.js"> </script>
          <h1 className={style.nameH1}>
            {' '}
            <span className={style.bigName} onClick={refreshPage}>
              N
            </span>
            <span onClick={refreshPage}>icolas</span>{' '}
            <span className={style.bigName} onClick={refreshPage}>
              G
            </span>
            <span onClick={refreshPage}>oberville</span>
          </h1>
        </div>
      </div>
    </div>
  )
}

export default Header
