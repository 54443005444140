import html5 from '../assets/images/skills/html5.svg'
import css3 from '../assets/images/skills/css3.svg'
import js from '../assets/images/skills/js.svg'
import jquery from '../assets/images/skills/jquery.svg'
import php from '../assets/images/skills/php.svg'
import mysql from '../assets/images/skills/mysql.svg'
import bootstrap from '../assets/images/skills/bootstrap.svg'
import react from '../assets/images/skills/react.svg'
import wordpress from '../assets/images/skills/wordpress.svg'
import java from '../assets/images/skills/java.svg'
import kotlin from '../assets/images/skills/kotlin.svg'
import unity from '../assets/images/skills/unity.svg'
import csharp from '../assets/images/skills/csharp.svg'
import processing from '../assets/images/skills/processing.svg'
import construct from '../assets/images/skills/construct.svg'
import gamemaker from '../assets/images/skills/gamemaker.svg'
import node from '../assets/images/skills/node.svg'
import git from '../assets/images/skills/git.svg'
import github from '../assets/images/skills/github.svg'
import sass from '../assets/images/skills/sass.svg'
import npm from '../assets/images/skills/npm.svg'

export const skillsList = [
  {
    logo: html5,
    name: 'html5', // will be used as (part of) mg alt.
    informations: 'Je maîtrise le langage HTML', // will be used as img title.
    usedInWeb: true,
    usedInProtogame: false,
  },
  {
    logo: css3,
    name: 'css3',
    informations: 'Je maîtrise le langage CSS',
    usedInWeb: true,
    usedInProtogame: false,
  },
  {
    logo: unity,
    name: 'unity',
    informations: "J'ai passé des centaines d'heures sur Unity",
    usedInWeb: false,
    usedInProtogame: true,
  },
  {
    logo: csharp,
    name: 'csharp',
    informations: "J'écris mes scripts Unity en C#",
    usedInWeb: false,
    usedInProtogame: true,
  },
  {
    logo: js,
    name: 'js',
    informations: 'Je maîtrise le langage Javascript',
    usedInWeb: true,
    usedInProtogame: true,
  },
  {
    logo: jquery,
    name: 'jquery',
    informations: 'Je maîtrise JQuery',
    usedInWeb: true,
    usedInProtogame: true,
  },
  {
    logo: php,
    name: 'php',
    informations: 'Je maîtrise le langage PHP',
    usedInWeb: true,
    usedInProtogame: false,
  },
  {
    logo: mysql,
    name: 'mysql',
    informations: 'Je maîtrise le langage SQL et le système MYSQL',
    usedInWeb: true,
    usedInProtogame: false,
  },
  {
    logo: node,
    name: 'node',
    informations:
      "J'utilise souvent Node pour construire et adapter rapidement mes projets",
    usedInWeb: true,
    usedInProtogame: false,
  },
  {
    logo: react,
    name: 'react',
    informations: "J'ai appris à réaliser des applications React",
    usedInWeb: true,
    usedInProtogame: false,
  },
  {
    logo: bootstrap,
    name: 'bootstrap',
    informations: 'Je maîtrise le framework Bootstrap',
    usedInWeb: true,
    usedInProtogame: false,
  },
  {
    logo: wordpress,
    name: 'wordpress',
    informations: 'Je sais réaliser des sites avec Wordpress',
    usedInWeb: true,
    usedInProtogame: false,
  },
  {
    logo: java,
    name: 'java',
    informations: 'Je maîtrise Java',
    usedInWeb: true,
    usedInProtogame: true,
  },
  {
    logo: kotlin,
    name: 'kotlin',
    informations: 'Je maîtrise Kotlin',
    usedInWeb: true,
    usedInProtogame: false,
  },
  {
    logo: processing,
    name: 'processing',
    informations: "J'aime beaucoup la simplicité de Processing",
    usedInWeb: false,
    usedInProtogame: true,
  },
  {
    logo: construct,
    name: 'construct',
    informations: 'Construct est parfait pour prototyper des idées simples',
    usedInWeb: false,
    usedInProtogame: true,
  },
  {
    logo: gamemaker,
    name: 'gamemaker',
    informations: 'Je sais aussi utiliser GameMaker',
    usedInWeb: false,
    usedInProtogame: true,
  },
  {
    logo: npm,
    name: 'npm',
    informations: "J'utilise régulièrement npm sur mes projets web",
    usedInWeb: true,
    usedInProtogame: false,
  },
  {
    logo: git,
    name: 'git',
    informations: "J'utilise régulièrement Git",
    usedInWeb: true,
    usedInProtogame: true,
  },
  {
    logo: github,
    name: 'github',
    informations: "J'utilise GitHub pour mes récents projets",
    usedInWeb: true,
    usedInProtogame: true,
  },
  {
    logo: sass,
    name: 'sass',
    informations:
      "J'utilise souvent Sass pour faciliter l'écriture de feuilles de style",
    usedInWeb: true,
    usedInProtogame: false,
  },
]
