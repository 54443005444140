import { useState, useEffect } from 'react'
import $ from 'jquery'
import AnimateHeight from 'react-animate-height'
import empty_image from '../assets/images/empty_image.png' // invisible img useful to set screenshotReady to true on load
import SkillImg from './SkillImg'
import { webList } from '../datas/webList'
import { skillsList } from '../datas/skillsList' // full list of my skills

function WebProdContent() {
  const totalWeb = 5 // the "last" of web prod (displayed first) is just the general description of my web works
  const [displayedWeb, displayWeb] = useState(totalWeb - 1)
  // this get the value of the wanted web prod during the time of the animation, before we pass it to displayedWeb
  const [webToDisplay, setWebToDisplay] = useState(null)
  const [modalDisplay, setModalDisplay] = useState('none')
  const [skillMouseIsOn, setSkillMouseIsOn] = useState(null)
  const [prodDivHeight, setProdDivHeight] = useState('auto')
  const [screenshotReady, setScreenshotReady] = useState(false)
  const [progressBar, setProgressBar] = useState(adaptProgressBar())

  useEffect(() => {
    if (screenshotReady) setProdDivHeight('auto')
  }, [screenshotReady])

  function prodTransitionStart(wantedProd) {
    setProdDivHeight(0)
    setScreenshotReady(false)
    setWebToDisplay(wantedProd)
  }

  function prodTransitionEnd() {
    if (webToDisplay != null) {
      displayWeb(webToDisplay)
      setProgressBar(adaptProgressBar())

      setWebToDisplay(null)
    }
  }

  function mod(n, m) {
    return ((n % m) + m) % m
  }

  function adaptProgressBar() {
    var trueWebIndex
    if (webToDisplay == null) trueWebIndex = 0
    else trueWebIndex = mod([webToDisplay + 1], totalWeb)
    var newProgressBar = ''
    for (var i = 0; i < totalWeb; i++) {
      if (i == trueWebIndex) newProgressBar = newProgressBar + '●'
      else newProgressBar = newProgressBar + '○'
    }
    return newProgressBar
  }

  // Close the modal when clicking anywhere else.
  window.onclick = function (event) {
    if (event.target.id == 'myWebSkillsModal') {
      setModalDisplay('none')
    }
  }

  // Close the modal when pressing any key.
  $(document).on('keydown', function () {
    setModalDisplay('none')
  })

  var webModal = (
    <div
      id="myWebSkillsModal"
      className="web-skills-modal"
      style={{ display: modalDisplay }}
    >
      <div className="web-skills-modal-content">
        <span
          className="close-web-skills-modal"
          onClick={() => setModalDisplay('none')}
        >
          &times;
        </span>
        Mes diverses compétences web :<br />
        <div className="modalSkillsDiv">
          {skillsList.map(({ logo, name, informations, usedInWeb }) =>
            usedInWeb ? (
              <SkillImg
                key={'_skill_' + name}
                skillsrc={logo}
                skillname={name}
                skilltitle={informations}
              />
            ) : null
          )}
        </div>
      </div>
    </div>
  )

  var webDescription = (
    <div>
      <AnimateHeight
        duration={500}
        height={prodDivHeight}
        delay={0}
        easing={'linear'}
        onAnimationEnd={() => prodTransitionEnd()}
      >
        <p>
          Développeur freelance spécialisé en{' '}
          <span className="important">front-end</span> utilisant&nbsp;
          <span
            className="skills-list"
            onClick={() => setModalDisplay('block')}
          >
            tous les outils à ma disposition
          </span>
          , je construis des sites de qualité professionnelle. Je peux aussi
          faire office de consultant sur votre projet de développement de site
          internet. Voici mes principales réalisations.
        </p>
        <img
          id="empty-img"
          src={empty_image}
          onLoad={() => {
            setScreenshotReady(true)
          }}
        />
      </AnimateHeight>
      {webModal}
    </div>
  )

  return (
    <div className="prod-content ten columns">
      {displayedWeb == totalWeb - 1
        ? webDescription
        : webList.map(
            (
              { url, id, name, screenshots, description, skills, otherInfos },
              webIndex
            ) =>
              webIndex == displayedWeb ? (
                <AnimateHeight
                  duration={500}
                  height={prodDivHeight}
                  delay={0}
                  easing={'linear'}
                  key={id}
                  onAnimationEnd={() => prodTransitionEnd()}
                >
                  <div>
                    <img
                      className="web-screenshots"
                      src={screenshots[0]}
                      alt={screenshots[1]}
                      onLoad={() => {
                        setScreenshotReady(true)
                      }}
                    />
                    <br />
                    <a href={url} target="_blank" rel="noreferrer">
                      {' '}
                      {name}
                    </a>
                    <br />
                    <p>{description}</p>
                    {skills.map((skill) =>
                      skillMouseIsOn == skill[2] ? (
                        <SkillImg
                          key={id + '_skill_' + skill[2]}
                          skillsrc={skill[0]}
                          skillname={skill[2]}
                          skilltitle={skill[3]}
                          onmouseleave={() => setSkillMouseIsOn(null)}
                        />
                      ) : (
                        <SkillImg
                          key={id + '_skill_' + skill[2]}
                          skillsrc={skill[1]}
                          skillname={skill[2]}
                          skilltitle={skill[3]}
                          onmouseenter={() => setSkillMouseIsOn(skill[2])}
                        />
                      )
                    )}
                    <br />
                    <span className="skills-precisions">{otherInfos}</span>
                  </div>
                </AnimateHeight>
              ) : null
          )}

      <div className="row">
        <button
          id={`web-prev`}
          className="prevnext"
          onClick={() => prodTransitionStart(mod([displayedWeb - 1], totalWeb))}
        >
          &larr;
        </button>

        <div className="progress-bar"> {progressBar} </div>

        <button
          id={`web-next`}
          className="prevnext"
          onClick={() => prodTransitionStart(mod([displayedWeb + 1], totalWeb))}
        >
          &rarr;
        </button>
      </div>
    </div>
  )
}

export default WebProdContent
