import { useState, useCallback, useEffect } from 'react'
// import { Unity, UnityContent } from 'react-unity-webgl'
import ProtoScreenshots from '../ProtoScreenshots'
import CantPlay from '../CantPlay'
import CantPlayTemp from '../CantPlayTemp'
import SkillImg from '../SkillImg'
import unity from '../../assets/images/skills/unity.svg'
import csharp from '../../assets/images/skills/csharp.svg'
import unity_uncolored from '../../assets/images/skills/unity-uncolored.svg'
import csharp_uncolored from '../../assets/images/skills/csharp-uncolored.svg'
import screenshot_0 from '../../assets/images/screenshots/protogame/2d3d/2d3d_0.png'
import screenshot_1 from '../../assets/images/screenshots/protogame/2d3d/2d3d_1.png'
import screenshot_2 from '../../assets/images/screenshots/protogame/2d3d/2d3d_2.png'
import screenshot_3 from '../../assets/images/screenshots/protogame/2d3d/2d3d_3.png'

function Protogame_7(props) {
  const [skillMouseIsOn, setSkillMouseIsOn] = useState(null)
  // const [unityContent, setUnityContent] = useState(null)
  const [/* proto, */ setProto] = useState(
    <button id="play-button" onClick={launchProto}>
      Lancer le prototype
    </button>
  )

  const screenshots = [screenshot_0, screenshot_1, screenshot_2, screenshot_3]

  useEffect(() => {
    /*
    const content = new UnityContent(
      '/Protogames/Protogame-7/OtherBuild/OtherBuild.json',
      '/Protogames/Protogame-7/OtherBuild/UnityLoader.js'
    )
    setUnityContent(content)
    */
  }, [])

  function launchProto() {
    setProto(protoToLoad)
  }

  const protoToLoad = (
    <div>
      {/*
      <div id="unityContainer" className="game">
        {unityContent && <Unity unityContent={unityContent} />}
      </div>
      */}
      <CantPlay />
    </div>
  )

  const trueDivAdapter = useCallback(() => {
    props.trueDivAdapter()
  }, [props])

  return (
    <div>
      {/*proto*/}
      <CantPlayTemp />
      <br />
      <ProtoScreenshots
        screenshots={screenshots}
        screenRowName="2d3d"
        trueDivAdapter={trueDivAdapter}
      />
      <br />
      <p>
        Prototype d&apos;un puzzle-game{' '}
        <span className="important">mélangeant 2D et 3D.</span>
        <br />
        Le concept en fait un jeu finalement{' '}
        <span className="important">très frustrant à jouer</span> (raison pour
        laquelle ce projet est resté à l&apos;état de prototype) mais un{' '}
        <span className="important">défi technique</span> relevé avec joie.
      </p>
      <div className="controls">
        <span className="important">CONTROLES</span> : <br />- La totalité des
        contrôles est expliquée en jeu
      </div>
      <br />
      <span className="important">Textes en anglais</span>
      <br />
      <br />

      {skillMouseIsOn === 'unity' ? (
        <SkillImg
          skillsrc={unity}
          skilltitle="Ce prototype a été créé sous Unity"
          skillname="unity"
          onmouseleave={() => setSkillMouseIsOn(null)}
        />
      ) : (
        <SkillImg
          skillsrc={unity_uncolored}
          skilltitle="Ce prototype a été créé sous Unity"
          skillname="unity"
          onmouseenter={() => setSkillMouseIsOn('unity')}
        />
      )}

      {skillMouseIsOn === 'csharp' ? (
        <SkillImg
          skillsrc={csharp}
          skilltitle="Les scripts ont été écrits en C#"
          skillname="csharp"
          onmouseleave={() => setSkillMouseIsOn(null)}
        />
      ) : (
        <SkillImg
          skillsrc={csharp_uncolored}
          skilltitle="Les scripts ont été écrits en C#"
          skillname="csharp"
          onmouseenter={() => setSkillMouseIsOn('csharp')}
        />
      )}
    </div>
  )
}

export default Protogame_7
