import { useState, useCallback } from 'react'
import ProtoScreenshots from '../ProtoScreenshots'
import CantPlay from '../CantPlay'
import CantPlayTemp from '../CantPlayTemp'
import SkillImg from '../SkillImg'
import processing from '../../assets/images/skills/processing.svg'
import js from '../../assets/images/skills/js.svg'
import processing_uncolored from '../../assets/images/skills/processing-uncolored.svg'
import js_uncolored from '../../assets/images/skills/js-uncolored.svg'
import screenshot_0 from '../../assets/images/screenshots/protogame/color/color_0.png'
import screenshot_1 from '../../assets/images/screenshots/protogame/color/color_1.png'
import screenshot_2 from '../../assets/images/screenshots/protogame/color/color_2.png'
import screenshot_3 from '../../assets/images/screenshots/protogame/color/color_3.png'

function Protogame_5(props) {
  const [skillMouseIsOn, setSkillMouseIsOn] = useState(null)
  const screenshots = [screenshot_0, screenshot_1, screenshot_2, screenshot_3]

  const [/* proto, */ setProto] = useState(
    <button id="play-button" onClick={launchProto}>
      Lancer le prototype
    </button>
  )

  function launchProto() {
    setProto(protoToLoad)
  }

  const protoToLoad = (
    <div>
      {/*
      <div id="colorgame" className="game" tabIndex="1">
        <iframe
          src="/Protogames/Protogame-5/colorgame.html"
          title="some color thing"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          scrolling="no"
        ></iframe>
      </div>
      */}
      <CantPlay />
    </div>
  )

  const trueDivAdapter = useCallback(() => {
    props.trueDivAdapter()
  }, [props])

  return (
    <div>
      {/*proto*/}
      <CantPlayTemp />
      <br />
      <ProtoScreenshots
        screenshots={screenshots}
        screenRowName="color"
        trueDivAdapter={trueDivAdapter}
      />
      <br />
      <p>
        Ce prototype basé sur{' '}
        <a
          href="https://youtu.be/fHAsaCO0lEs?t=1055"
          target="_blank"
          rel="noreferrer"
        >
          un minigame
        </a>{' '}
        de <span className="italic-name">Muppets Party Cruise</span> m&apos;a
        surtout servi à apprendre à utiliser Processing.
        <br />
        J&apos;ai repris suite à ce prototype le concept de{' '}
        <span className="important">switch entre les couleurs</span> dans un{' '}
        <span className="internal-link" onClick={() => props.displayPG(8)}>
          autre petit projet
        </span>
        , mais le concept mériterait d&apos;être encore creusé.
      </p>
      <div className="controls">
        <span className="important">CONTROLES</span> : <br />-
        <span className="important">Cliquer</span> sur une tuile avec la{' '}
        <span className="important">souris</span> et en{' '}
        <span className="important">maintenant</span> le clic,{' '}
        <span className="important">déplacer</span> la souris vers une autre
        tuile avec laquelle vous souhaitez échanger la couleur
      </div>
      <br />

      {skillMouseIsOn === 'processing' ? (
        <SkillImg
          skillsrc={processing}
          skilltitle="Ce prototype a initialement été codé en Processing"
          skillname="processing"
          onmouseleave={() => setSkillMouseIsOn(null)}
        />
      ) : (
        <SkillImg
          skillsrc={processing_uncolored}
          skilltitle="Ce prototype a initialement été codé en Processing"
          skillname="processing"
          onmouseenter={() => setSkillMouseIsOn('processing')}
        />
      )}

      {skillMouseIsOn === 'js' ? (
        <SkillImg
          skillsrc={js}
          skilltitle="Ce prototype a été porté sur navigateur avec Processing.js"
          skillname="js"
          onmouseleave={() => setSkillMouseIsOn(null)}
        />
      ) : (
        <SkillImg
          skillsrc={js_uncolored}
          skilltitle="Ce prototype a été porté sur navigateur avec Processing.js"
          skillname="js"
          onmouseenter={() => setSkillMouseIsOn('js')}
        />
      )}
    </div>
  )
}

export default Protogame_5
