import { useState, useCallback, useEffect } from 'react'
import ProtoScreenshots from '../ProtoScreenshots'
import CantPlay from '../CantPlay'
import CantPlayTemp from '../CantPlayTemp'
import SkillImg from '../SkillImg'
import unity from '../../assets/images/skills/unity.svg'
import csharp from '../../assets/images/skills/csharp.svg'
import git from '../../assets/images/skills/git.svg'
import github from '../../assets/images/skills/github.svg'
import unity_uncolored from '../../assets/images/skills/unity-uncolored.svg'
import csharp_uncolored from '../../assets/images/skills/csharp-uncolored.svg'
import git_uncolored from '../../assets/images/skills/git-uncolored.svg'
import github_uncolored from '../../assets/images/skills/github-uncolored.svg'
import screenshot_0 from '../../assets/images/screenshots/protogame/cube/cube_0.png'
import screenshot_1 from '../../assets/images/screenshots/protogame/cube/cube_1.png'
import screenshot_2 from '../../assets/images/screenshots/protogame/cube/cube_2.png'
import screenshot_3 from '../../assets/images/screenshots/protogame/cube/cube_3.png'

function Protogame_9(props) {
  const [skillMouseIsOn, setSkillMouseIsOn] = useState(null)

  // Définir la fonction launchProto avant d'utiliser useState
  const launchProto = useCallback(() => {
    setProto(protoToLoad)
  }, [])

  // Définir le contenu de protoToLoad avant de l'utiliser dans launchProto
  const protoToLoad = (
    <div>
      <div id="cube" className="game" tabIndex="1">
        <iframe
          src="Protogames/Protogame-9/index.html"
          title="some wall sokoban prototype"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          scrolling="no"
        ></iframe>
      </div>
      <CantPlay />
    </div>
  )

  // Initialiser le state de proto en utilisant launchProto
  const [/* proto, */ setProto] = useState(
    <button id="play-button" onClick={launchProto}>
      Lancer le prototype
    </button>
  )

  const screenshots = [screenshot_0, screenshot_1, screenshot_2, screenshot_3]

  const trueDivAdapter = useCallback(() => {
    props.trueDivAdapter()
  }, [props])

  // Utiliser useEffect pour remplacer onLoad
  useEffect(() => {
    props.ondisplay()
  }, [props])

  return (
    <div>
      {/*proto*/}
      <CantPlayTemp />
      <br />
      <ProtoScreenshots
        screenshots={screenshots}
        screenRowName="cube"
        trueDivAdapter={trueDivAdapter}
      />
      <br />
      <p>
        La création de ce prototype axé{' '}
        <span className="important">puzzle</span> était avant tout un défi
        technique, pour comprendre un peu mieux le fonctionnement des{' '}
        <span className="important">textures</span>, en particulier sur Unity.
      </p>
      <div className="controls">
        <span className="important">CONTROLES</span> : <br />
        <span className="important">Touches directionnelles</span> pour les
        déplacements de case en case
        <br />
        <span className="important">R</span> pour recommencer le niveau
        <br />
      </div>
      <br />
      <SkillImg
        skillsrc={skillMouseIsOn === 'unity' ? unity : unity_uncolored}
        skilltitle="Ce prototype a été créé sous Unity"
        skillname="unity"
        onmouseenter={() => setSkillMouseIsOn('unity')}
        onmouseleave={() => setSkillMouseIsOn(null)}
      />
      <SkillImg
        skillsrc={skillMouseIsOn === 'csharp' ? csharp : csharp_uncolored}
        skilltitle="Les scripts ont été écrits en C#"
        skillname="csharp"
        onmouseenter={() => setSkillMouseIsOn('csharp')}
        onmouseleave={() => setSkillMouseIsOn(null)}
      />
      <SkillImg
        skillsrc={skillMouseIsOn === 'git' ? git : git_uncolored}
        skilltitle="J'ai géré ce projet avec Git"
        skillname="git"
        onmouseenter={() => setSkillMouseIsOn('git')}
        onmouseleave={() => setSkillMouseIsOn(null)}
      />
      <SkillImg
        skillsrc={skillMouseIsOn === 'github' ? github : github_uncolored}
        skilltitle="J'ai géré ce projet avec GitHub"
        skillname="github"
        onmouseenter={() => setSkillMouseIsOn('github')}
        onmouseleave={() => setSkillMouseIsOn(null)}
      />
    </div>
  )
}

export default Protogame_9
