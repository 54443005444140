import { useState, useEffect } from 'react'

function AddGameManually({ onModifyGame }) {
  const [gameName, setGameName] = useState('')
  const [isFree, setIsFree] = useState(false)
  const [comments, setComments] = useState('')
  const [addedGameId, setAddedGameId] = useState(null)
  const [platforms, setPlatforms] = useState([])
  const [subplatforms, setSubplatforms] = useState([])
  const [owners, setOwners] = useState([])
  const [selectedPlatform, setSelectedPlatform] = useState('')
  const [selectedOwner, setSelectedOwner] = useState('')
  const [selectedSubplatform, setSelectedSubplatform] = useState('')
  const [playstationPlus, setPlaystationPlus] = useState(false)
  const [coverUrl, setCoverUrl] = useState('')
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false) // État pour gérer le chargement

  useEffect(() => {
    const controller = new AbortController() // Crée un AbortController pour annuler les fetches
    const signal = controller.signal

    async function fetchData() {
      try {
        const platformsResponse = await fetch('/api/pg/platforms', { signal })
        const platformsData = await platformsResponse.json()
        setPlatforms(platformsData)

        const subplatformsResponse = await fetch('/api/pg/subplatforms', {
          signal,
        })
        const subplatformsData = await subplatformsResponse.json()
        setSubplatforms(subplatformsData)

        const ownersResponse = await fetch('/api/pg/owners', { signal })
        const ownersData = await ownersResponse.json()
        setOwners(ownersData)
      } catch (err) {
        if (err.name !== 'AbortError') {
          // Ignore l'erreur si la requête a été annulée
          console.error(
            'Erreur lors du chargement des plateformes, sous-plateformes ou propriétaires',
            err
          )
        }
      }
    }

    fetchData()

    return () => {
      controller.abort() // Annule les requêtes si le composant est démonté
    }
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Validation des champs
    if (!gameName.trim() || !selectedPlatform || !selectedOwner) {
      alert('Veuillez remplir tous les champs requis.')
      return
    }

    setLoading(true) // Démarre l'état de chargement

    try {
      // Ajout du jeu dans la table `games`
      const response = await fetch('/api/add-game', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          igdb_id: null, // Envoyer null pour les ajouts manuels
          name: gameName,
          is_free: isFree,
          addition_date: new Date().toISOString(),
          cover_url: coverUrl,
          comments: comments,
        }),
      })

      const result = await response.json()

      if (response.ok) {
        const gameId = result.game.id
        setAddedGameId(gameId) // Enregistre l'ID du jeu ajouté
        // Ajout des informations dans la table `game_platforms`
        const gamePlatformResponse = await fetch('/api/add-game-platform', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            game_id: gameId, // Passer le game_id ici pour lier les possessions au jeu
            possessions: [
              {
                platform_id: selectedPlatform,
                playstation_plus: playstationPlus,
                owner_id: selectedOwner,
                subplatform_id: selectedSubplatform || null, // Envoyer null si aucune sous-plateforme n'est sélectionnée
              },
            ],
          }),
        })

        const gamePlatformResult = await gamePlatformResponse.json()

        if (gamePlatformResponse.ok) {
          setSuccess(true)
          setGameName('')
          setIsFree(false)
          setComments('')
          setSelectedPlatform('')
          setSelectedOwner('')
          setSelectedSubplatform('')
          setPlaystationPlus(false)
        } else {
          alert('Erreur: ' + gamePlatformResult.error)
        }
      } else {
        alert('Erreur: ' + result.error)
      }
    } catch (err) {
      alert("Erreur lors de l'ajout du jeu")
      console.error(err)
    } finally {
      setLoading(false) // Arrête l'état de chargement
    }
  }

  return (
    <div>
      {loading ? (
        <p>Chargement en cours...</p> // Indicateur de chargement
      ) : success ? (
        <div>
          <p>Jeu ajouté avec succès !</p>
          <button onClick={() => onModifyGame(addedGameId)}>
            Modifier ce jeu
          </button>
        </div>
      ) : (
        <div className="add-game-container">
          <form className="add-game-form" onSubmit={handleSubmit}>
            <label>
              Nom du jeu : &nbsp;
              <input
                type="text"
                value={gameName}
                onChange={(e) => setGameName(e.target.value)}
                required
              />
            </label>
            <label>
              <input
                type="checkbox"
                checked={isFree}
                onChange={(e) => setIsFree(e.target.checked)}
              />
              &nbsp; Gratuit
            </label>
            <label>
              <input
                type="checkbox"
                checked={playstationPlus}
                onChange={(e) => setPlaystationPlus(e.target.checked)}
              />
              &nbsp; Playstation Plus
            </label>
            <label>
              Propriétaire : &nbsp;
              <select
                value={selectedOwner}
                onChange={(e) => setSelectedOwner(e.target.value)}
                required
              >
                <option value="">Sélectionner un possesseur</option>
                {owners.map((owner) => (
                  <option key={owner.id} value={owner.id}>
                    {owner.name}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Plateforme : &nbsp;
              <select
                value={selectedPlatform}
                onChange={(e) => setSelectedPlatform(e.target.value)}
                required
              >
                <option value="">Sélectionner une plateforme</option>
                {platforms.map((platform) => (
                  <option key={platform.id} value={platform.id}>
                    {platform.name}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Sous-plateforme (optionnelle) : &nbsp;
              <select
                value={selectedSubplatform}
                onChange={(e) => setSelectedSubplatform(e.target.value)}
              >
                <option value="">Sélectionner une sous-plateforme</option>
                {subplatforms.map((subplatform) => (
                  <option key={subplatform.id} value={subplatform.id}>
                    {subplatform.name}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Image de couverture (lien) : &nbsp;
              <input
                type="text"
                value={coverUrl}
                onChange={(e) => setCoverUrl(e.target.value)}
                placeholder="Lien vers la jaquette"
              />
            </label>
            <label>
              <span className="text-input-name">Commentaires :</span>
              <textarea
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                placeholder="Ajoutez un commentaire"
              />
            </label>
            <button type="submit">Ajouter le jeu</button>
          </form>
        </div>
      )}
    </div>
  )
}

export default AddGameManually
