import { useState } from 'react'
import $ from 'jquery'
import Protogame from './Protogame'

function PGProdContent(props) {
  const totalPG = 10 // the "first" (0) of protogame prod is just the general description of my protogame works
  const [displayedPG, displayPG] = useState(0)
  const [progressBar, setProgressBar] = useState(adaptProgressBar())

  var protoWidth

  function mod(n, m) {
    return ((n % m) + m) % m
  }

  function adaptProgressBar() {
    var newProgressBar = ''
    for (var i = 0; i < totalPG; i++) {
      if (i == displayedPG) newProgressBar = newProgressBar + '●'
      else newProgressBar = newProgressBar + '○'
    }
    return newProgressBar
  }

  // make the protogames unavailable on small screen. This has to be adapted each time i add or switch protogames
  function adaptProtoToWidth() {
    if (displayedPG == 1) {
      if ($(window).width() <= 700) {
        $('#unityContainer').css('display', 'none')
        $('#cant-play').css('display', 'block')
        if ($(window).width() < 550) {
          $('#unity-screen-row-1 img').removeClass('six columns')
          $('#unity-screen-row-2 img').removeClass('six columns')
          $('.screen-br').css('display', 'none')
        } else {
          $('#unity-screen-row-1 img').addClass('six columns')
          $('#unity-screen-row-2 img').addClass('six columns')
          $('.screen-br').css('display', 'inline')
        }
      } else {
        $('#unityContainer').css('display', 'block')
        protoWidth = $('#unityContainer').width()
        $('#unityContainer').css('height', protoWidth * 0.5625)
        $('#cant-play').css('display', 'none')
      }
    } else if (displayedPG == 2) {
      if ($(window).width() > 2050) {
        $('#construct-mmo-g').css('display', 'block')
        protoWidth = $('#construct-mmo-g').width()
        $('#cant-play').css('display', 'none')
      } else {
        $('#construct-mmo-g').css('display', 'none')
        $('#cant-play').css('display', 'block')
        if ($(window).width() < 550) {
          $('#mmo-screen-row-1 img').removeClass('six columns')
          $('#mmo-screen-row-2 img').removeClass('six columns')
          $('.screen-br').css('display', 'none')
        } else {
          $('#mmo-screen-row-1 img').addClass('six columns')
          $('#mmo-screen-row-2 img').addClass('six columns')
          $('.screen-br').css('display', 'inline')
        }
      }
    } else if (displayedPG == 3) {
      if ($(window).width() <= 650 || $(window).height() <= 650) {
        $('#construct-g').css('display', 'none')
        $('#cant-play').css('display', 'block')
        if ($(window).width() < 550 || $(window).height() < 550) {
          $('#gravity-screen-row-1 img').removeClass('six columns')
          $('#gravity-screen-row-2 img').removeClass('six columns')
          $('.screen-br').css('display', 'none')
        } else {
          $('#gravity-screen-row-1 img').addClass('six columns')
          $('#gravity-screen-row-2 img').addClass('six columns')
          $('.screen-br').css('display', 'inline')
        }
      } else {
        $('#construct-g').css('display', 'block')
        protoWidth = $('#construct-g').width()
        $('#cant-play').css('display', 'none')
      }
    } else if (displayedPG == 4) {
      if ($(window).width() <= 550) {
        $('#bubble').css('display', 'none')
        $('#cant-play').css('display', 'block')
        if ($(window).width() < 550) {
          $('#bubble-screen-row-1 img').removeClass('six columns')
          $('#bubble-screen-row-2 img').removeClass('six columns')
          $('.screen-br').css('display', 'none')
        } else {
          $('#bubble-screen-row-1 img').addClass('six columns')
          $('#bubble-screen-row-2 img').addClass('six columns')
          $('.screen-br').css('display', 'inline')
        }
      } else {
        $('#bubble').css('display', 'block')
        protoWidth = $('#bubble').width()
        $('#cant-play').css('display', 'none')
      }
    } else if (displayedPG == 5) {
      if ($(window).width() <= 760) {
        $('#colorgame').css('display', 'none')
        $('#cant-play').css('display', 'block')
        if ($(window).width() < 550) {
          $('#color-screen-row-1 img').removeClass('six columns')
          $('#color-screen-row-2 img').removeClass('six columns')
          $('.screen-br').css('display', 'none')
        } else {
          $('#color-screen-row-1 img').addClass('six columns')
          $('#color-screen-row-2 img').addClass('six columns')
          $('.screen-br').css('display', 'inline')
        }
      } else {
        $('#colorgame').css('display', 'block')
        protoWidth = $('#colorgame').width()
        $('#cant-play').css('display', 'none')
      }
    } else if (displayedPG == 6) {
      if (
        $(window).width() > 1050 ||
        ($(window).width() < 910 && $(window).width() > 875)
      ) {
        $('#anothercolorgame').css('display', 'block')
        protoWidth = $('#anothercolorgame').width()
        $('#cant-play').css('display', 'none')
      } else {
        $('#anothercolorgame').css('display', 'none')
        $('#cant-play').css('display', 'block')
        if ($(window).width() < 550) {
          $('#acolor-screen-row-1 img').removeClass('six columns')
          $('#acolor-screen-row-2 img').removeClass('six columns')
          $('.screen-br').css('display', 'none')
        } else {
          $('#acolor-screen-row-1 img').addClass('six columns')
          $('#acolor-screen-row-2 img').addClass('six columns')
          $('.screen-br').css('display', 'inline')
        }
      }
    } else if (displayedPG == 7) {
      if ($(window).width() <= 700) {
        $('#unityContainer').css('display', 'none')
        $('#cant-play').css('display', 'block')
        if ($(window).width() < 550) {
          $('#2d3d-screen-row-1 img').removeClass('six columns')
          $('#2d3d-screen-row-2 img').removeClass('six columns')
          $('.screen-br').css('display', 'none')
        } else {
          $('#2d3d-screen-row-1 img').addClass('six columns')
          $('#2d3d-screen-row-2 img').addClass('six columns')
          $('.screen-br').css('display', 'inline')
        }
      } else {
        $('#unityContainer').css('display', 'block')
        protoWidth = $('#unityContainer').width()
        $('#unityContainer').css('height', protoWidth * 0.5625)
        $('#cant-play').css('display', 'none')
      }
    } else if (displayedPG == 8) {
      if ($(window).width() <= 1150) {
        $('#ibpg').css('display', 'none')
        $('#cant-play').css('display', 'block')
        if ($(window).width() < 550) {
          $('#ibpg-screen-row-1 img').removeClass('six columns')
          $('#ibpg-screen-row-2 img').removeClass('six columns')
          $('.screen-br').css('display', 'none')
        } else {
          $('#ibpg-screen-row-1 img').addClass('six columns')
          $('#ibpg-screen-row-2 img').addClass('six columns')
          $('.screen-br').css('display', 'inline')
        }
      } else {
        $('#ibpg').css('display', 'block')
        protoWidth = $('#ibpg').width()
        $('#ibpg').css('height', protoWidth * 0.5625)
        $('#cant-play').css('display', 'none')
      }
    } else if (displayedPG == 9) {
      if ($(window).width() <= 1240) {
        $('#cube').css('display', 'none')
        $('#cant-play').css('display', 'block')
        if ($(window).width() < 550) {
          $('#cube-screen-row-1 img').removeClass('six columns')
          $('#cube-screen-row-2 img').removeClass('six columns')
          $('.screen-br').css('display', 'none')
        } else {
          $('#cube-screen-row-1 img').addClass('six columns')
          $('#cube-screen-row-2 img').addClass('six columns')
          $('.screen-br').css('display', 'inline')
        }
      } else {
        $('#cube').css('display', 'block')
        protoWidth = $('#cube').width()
        $('#cant-play').css('display', 'none')
      }
    }
  }

  var addEvent = function (object, type, callback) {
    if (object == null || typeof object == 'undefined') return
    if (object.addEventListener) {
      object.addEventListener(type, callback, false)
    } else if (object.attachEvent) {
      object.attachEvent('on' + type, callback)
    } else {
      object['on' + type] = callback
    }
  }

  addEvent(window, 'resize', function () {
    adaptProtoToWidth()
  })

  return (
    <div className="prod-content ten columns">
      <Protogame
        selected={displayedPG}
        ondisplay={adaptProtoToWidth}
        displayPG={displayPG}
        initAdaptedScroll={props.initAdaptedScroll}
        setProgressBar={setProgressBar}
        adaptProgressBar={adaptProgressBar}
      />
      <div className="row">
        <button
          id={`PG-prev`}
          className="prevnext"
          onClick={() => displayPG(mod([displayedPG - 1], totalPG))}
        >
          &larr;
        </button>

        <div className="progress-bar"> {progressBar} </div>

        <button
          id={`PG-next`}
          className="prevnext"
          onClick={() => displayPG(mod([displayedPG + 1], totalPG))}
        >
          &rarr;
        </button>
      </div>
    </div>
  )
}

export default PGProdContent
