import { useState, useEffect } from 'react'
import ProtoScreenshots from '../ProtoScreenshots'
import CantPlay from '../CantPlay'
import CantPlayTemp from '../CantPlayTemp'
import SkillImg from '../SkillImg'
import construct from '../../assets/images/skills/construct.svg'
import construct_uncolored from '../../assets/images/skills/construct-uncolored.svg'
import screenshot_0 from '../../assets/images/screenshots/protogame/mmo/mmo_0.png'
import screenshot_1 from '../../assets/images/screenshots/protogame/mmo/mmo_1.png'
import screenshot_2 from '../../assets/images/screenshots/protogame/mmo/mmo_2.png'
import screenshot_3 from '../../assets/images/screenshots/protogame/mmo/mmo_3.png'

function Protogame_2(props) {
  const [skillMouseIsOn, setSkillMouseIsOn] = useState(null)
  const [/* proto, */ setProto] = useState(
    <button id="play-button" onClick={() => launchProto()}>
      Lancer le prototype
    </button>
  )

  const screenshots = [screenshot_0, screenshot_1, screenshot_2, screenshot_3]

  const protoToLoad = (
    <div>
      {/*
      <div id="construct-mmo-g">
        <iframe
          src="/Protogames/Protogame-2"
          title="some MMO game"
          frameBorder="0"
          marginHeight=""
          marginWidth="0"
        ></iframe>
      </div>
      */}
      <CantPlay />
    </div>
  )

  function launchProto() {
    setProto(protoToLoad)
  }

  function trueDivAdapter() {
    props.trueDivAdapter()
  }

  useEffect(() => {
    // Trigger the `ondisplay` function if it is passed as a prop
    if (props.ondisplay) {
      props.ondisplay()
    }
  }, [props.ondisplay]) // Runs when `props.ondisplay` changes

  return (
    <div>
      {/*proto*/}
      <CantPlayTemp />
      <br />
      <ProtoScreenshots
        screenshots={screenshots}
        screenRowName="mmo"
        trueDivAdapter={trueDivAdapter}
      />
      <br />
      <p>
        Ce prototype de 2015-2016 a pour origine un projet étudiant sur
        l&apos;initiative de{' '}
        <a
          className="specialLink italic-name"
          href="https://bit.ly/2VZV4Nd"
          target="_blank"
          rel="noreferrer"
        >
          Thomas Planques
        </a>
        , professeur de game-design, ayant pour objectif de sensibiliser les
        élèves et les joueurs aux problèmes de{' '}
        <span className="important">sexisme dans le milieu du jeu vidéo</span>.
        <br />
        Ma proposition a été de faire un{' '}
        <span className="important">faux mmorpg</span> dans lequel la joueuse
        (ou le joueur) se retrouve elle-même victime de cyberharcèlement. Après
        avoir bien été reçu par un{' '}
        <span className="important">jury professionnel</span> puis peaufiné
        pendant un temps, le projet a été mis en pause.
        <br />
        En effet je n&apos;apreciais plus le faux-gameplay lourdingue ni
        l&apos;absence de subtilité dans les dialogues. Je propose cependant
        d&apos;y jeter un œil pour les idées et concepts employé(e)s, ou
        simplement parce que le sujet en lui-même mérite toujours d&apos;être
        abordé.
        <br />
        J&apos;envisage de recommencer un projet de ce genre un jour avec
        autrement plus de consistance et de subtilité.
        <br />
        <span className="important">
          ATTENTION : Les situations mises en scène ici peuvent être génantes
          (c&apos;est leur but). Veuillez noter aussi que le jeu n&apos;est pas
          finissable en raison de problèmes techniques.
        </span>
      </p>
      <div className="controls">
        <span className="important">CONTROLES</span> : <br />-
        <span className="important">Souris</span> dans le faux menu de lancement
        <br />
        -Les contrôles sont expliqués en jeu (utilisez les{' '}
        <span className="important">flèches directionnelles</span> pour les
        déplacements)
      </div>
      <br />
      <span className="important">Textes en anglais</span>
      <br />
      <br />
      {skillMouseIsOn === 'construct' ? (
        <SkillImg
          skillsrc={construct}
          skilltitle="Ce prototype a été créé sous Construct 2"
          skillname="construct"
          onmouseleave={() => setSkillMouseIsOn(null)}
        />
      ) : (
        <SkillImg
          skillsrc={construct_uncolored}
          skilltitle="Ce prototype a été créé sous Construct 2"
          skillname="construct"
          onmouseenter={() => setSkillMouseIsOn('construct')}
        />
      )}
    </div>
  )
}

export default Protogame_2
