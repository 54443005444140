import { useState, useRef, useEffect } from 'react'

function Footer() {
  const currentYear = new Date().getFullYear()
  const [isFormVisible, setIsFormVisible] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [formData, setFormData] = useState({
    from_name: '',
    email: '',
    message: '',
  })

  const formRef = useRef()

  useEffect(() => {
    // Charger le script EmailJS
    const script = document.createElement('script')
    script.src =
      'https://cdn.jsdelivr.net/npm/@emailjs/browser@4/dist/email.min.js'
    script.async = true
    document.body.appendChild(script)

    script.onload = () => {
      // Initialiser EmailJS avec la clé publique
      window.emailjs.init(process.env.REACT_APP_MAIL_PUBLIC_KEY) // Remplacez 'YOUR_PUBLIC_KEY' par votre clé publique
    }

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const sendEmail = (e) => {
    e.preventDefault()

    window.emailjs
      .sendForm(
        process.env.REACT_APP_MAIL_SERVICE_ID,
        process.env.REACT_APP_MAIL_TEMPLATE_ID,
        formRef.current
      )
      .then(
        () => {
          setFormSubmitted(true)
          setFormData({ from_name: '', email: '', message: '' }) // Réinitialise le formulaire
          setErrorMessage('') // Réinitialise le message d'erreur
        },
        (error) => {
          console.log('FAILED...', error.text)
          setErrorMessage(
            "Erreur lors de l'envoi du message. Veuillez réessayer plus tard."
          )
        }
      )
  }

  return (
    <footer>
      {!isFormVisible && !formSubmitted && (
        <button className="cta-button" onClick={() => setIsFormVisible(true)}>
          Me contacter
        </button>
      )}
      {isFormVisible && !formSubmitted && (
        <div>
          <p>
            Besoin d&apos;aide pour un projet ou une idée ? Contactez-moi, je
            serai ravi d&apos;en discuter !
          </p>
          <form id="contact-form-main" ref={formRef} onSubmit={sendEmail}>
            <div className="contact-form">
              <div className="left-section">
                <input type="hidden" name="to_name" value="Nicolas" required />
                <div>
                  <label htmlFor="from_name">Nom</label>
                  <input
                    type="text"
                    name="from_name"
                    required
                    value={formData.from_name}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    value={formData.email}
                    required
                  />
                </div>
              </div>
              <div className="right-section">
                <div>
                  <label htmlFor="message">Message</label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
            </div>
            <button type="submit">Envoyer</button>
            {errorMessage && (
              <p className="contact-error-message">{errorMessage}</p>
            )}
          </form>
        </div>
      )}
      {formSubmitted && (
        <p>Merci pour votre message ! Je reviendrai vers vous rapidement.</p>
      )}
      <br />
      <a href="mailto:goberville.nicolas@gmail.com">
        goberville.nicolas@gmail.com
      </a>
      <p>© {currentYear} Nicolas Goberville</p>
    </footer>
  )
}

export default Footer
