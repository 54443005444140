import eg from '../assets/images/screenshots/web/eg.png'
import ge from '../assets/images/screenshots/web/ge.png'
import ng from '../assets/images/screenshots/web/ng.png'
import bt from '../assets/images/screenshots/web/bt.png'

import react from '../assets/images/skills/react.svg'
import html5 from '../assets/images/skills/html5.svg'
import css3 from '../assets/images/skills/css3.svg'
import js from '../assets/images/skills/js.svg'
import jquery from '../assets/images/skills/jquery.svg'
import php from '../assets/images/skills/php.svg'
import bootstrap from '../assets/images/skills/bootstrap.svg'
import npm from '../assets/images/skills/npm.svg'
import git from '../assets/images/skills/git.svg'
import github from '../assets/images/skills/github.svg'
import normalize from '../assets/images/skills/normalize.svg'
import sass from '../assets/images/skills/sass.svg'
import mysql from '../assets/images/skills/mysql.svg'

import react_uncolored from '../assets/images/skills/react-uncolored.svg'
import html5_uncolored from '../assets/images/skills/html5-uncolored.svg'
import css3_uncolored from '../assets/images/skills/css3-uncolored.svg'
import js_uncolored from '../assets/images/skills/js-uncolored.svg'
import jquery_uncolored from '../assets/images/skills/jquery-uncolored.svg'
import php_uncolored from '../assets/images/skills/php-uncolored.svg'
import bootstrap_uncolored from '../assets/images/skills/bootstrap-uncolored.svg'
import npm_uncolored from '../assets/images/skills/npm-uncolored.svg'
import git_uncolored from '../assets/images/skills/git-uncolored.svg'
import github_uncolored from '../assets/images/skills/github-uncolored.svg'
import normalize_uncolored from '../assets/images/skills/normalize-uncolored.svg'
import sass_uncolored from '../assets/images/skills/sass-uncolored.svg'
import mysql_uncolored from '../assets/images/skills/mysql-uncolored.svg'

// The id are arbitrary-given.

export const webList = [
  {
    url: 'https://elsagoberville.com',
    name: 'elsagoberville.com',
    id: '1ab',
    screenshots: [eg, "Captures d'écran de elsagoberville.com"],
    description:
      "J'ai créé de A à Z le site portfolio professionnel de ma sœur. Jetez-y un œil ! ",
    skills: [
      [
        bootstrap,
        bootstrap_uncolored,
        'bootstrap',
        'Ce site est construit avec Bootstrap',
      ],
      [html5, html5_uncolored, 'html5', "J'ai manipulé du HTML pour ce projet"],
      [css3, css3_uncolored, 'css3', "J'ai manipulé du CSS pour ce projet"],
      [js, js_uncolored, 'js', "J'ai manipulé du Javascript pour ce projet"],
      [
        jquery,
        jquery_uncolored,
        'jquery',
        "J'ai manipulé du JQuery pour ce projet",
      ],
      [php, php_uncolored, 'php', "J'ai manipulé du PHP pour ce projet"],
      [git, git_uncolored, 'git', "J'ai géré ce projet avec Git"],
    ],
  },

  {
    url: 'https://gregoryernestcoaching.com/',
    name: 'gregoryernestcoaching.com',
    id: '2cd',
    screenshots: [ge, "Captures d'écran de gregoryernestcoaching.com"],
    description:
      "J'ai développé l'entièreté du site et blog de Gregory Ernest, coach sportif.",
    skills: [
      [
        bootstrap,
        bootstrap_uncolored,
        'bootstrap',
        'Ce site est construit avec Bootstrap',
      ],
      [html5, html5_uncolored, 'html5', "J'ai manipulé du HTML pour ce projet"],
      [css3, css3_uncolored, 'css3', "J'ai manipulé du CSS pour ce projet"],
      [js, js_uncolored, 'js', "J'ai manipulé du Javascript pour ce projet"],
      [
        jquery,
        jquery_uncolored,
        'jquery',
        "J'ai manipulé du JQuery pour ce projet",
      ],
    ],
    otherInfos: 'Aussi utilisés : SimpleMDE, PHPMailer, ...',
  },

  {
    url: 'https://ngoberville.com',
    name: 'ngoberville.com',
    id: '3ef',
    screenshots: [ng, "Captures d'écran de ngoberville.com"],
    description:
      'Ce site sur lequel vous vous trouvez me sert à exposer quelques-uns de mes travaux.',
    skills: [
      [react, react_uncolored, 'react', 'Ce site a été reconstruit avec React'],
      [html5, html5_uncolored, 'html5', "J'ai manipulé du HTML pour ce projet"],
      [css3, css3_uncolored, 'css3', "J'ai manipulé du CSS pour ce projet"],
      [js, js_uncolored, 'js', "J'ai manipulé du Javascript pour ce projet"],
      [
        jquery,
        jquery_uncolored,
        'jquery',
        "J'ai manipulé du JQuery pour ce projet",
      ],
      [npm, npm_uncolored, 'npm', "J'ai utilisé npm pour ce projet"],
      [git, git_uncolored, 'git', "J'ai géré ce projet avec Git"],
      [github, github_uncolored, 'github', "J'ai géré ce projet avec GitHub"],
      [
        normalize,
        normalize_uncolored,
        'normalize',
        'Ce site utilise normalize.css',
      ],
    ],
    otherInfos: 'Ce site est aussi construit avec le framework Skeleton',
  },

  {
    url: 'https://www.ngoberville.com/demos/sites/bluetrip/index',
    name: '"Blue Trip"',
    id: '4gh',
    screenshots: [bt, "Captures d'écran de la demo Blue Trip"],
    description:
      "Site (démonstratif) d'un groupe de jazz local, avec back-office et base de données dynamique.",
    skills: [
      [html5, html5_uncolored, 'html5', "J'ai manipulé du HTML pour ce projet"],
      [css3, css3_uncolored, 'css3', "J'ai manipulé du CSS pour ce projet"],
      [js, js_uncolored, 'js', "J'ai manipulé du Javascript pour ce projet"],
      [
        sass,
        sass_uncolored,
        'sass',
        "J'ai utilisé Sass pour faciliter la création du style",
      ],
      [
        jquery,
        jquery_uncolored,
        'jquery',
        "J'ai manipulé du JQuery pour ce projet",
      ],
      [php, php_uncolored, 'php', "J'ai manipulé du PHP pour ce projet"],
      [
        mysql,
        mysql_uncolored,
        'mysql',
        "J'ai manipulé SQL et MySQL pour ce projet",
      ],
    ],
    otherInfos: "Ce site utilise aussi l'editeur markdown SimpleMDE",
  },
]
