import { /* React, */ useEffect } from 'react'
import $ from 'jquery'
import '../styles/Prod.css'
import ProdCategory from './ProdCategory'
import WebProdContent from './WebProdContent'
import PGProdContent from './PGProdContent'

// this is the all prods part, including websites and game prototypes
function Prod() {
  function adaptProdToWidth() {
    if ($(window).width() <= 910) {
      $('.hidden-prod').css('display', 'grid')
      $('#prod-web .prod-category').css('display', 'none')
      $('#prod-web .prod-content').removeClass('ten')
      $('#prod-web .prod-content').addClass('twelve')
      $('#prod-protogame .prod-category').css('display', 'none')
      $('#prod-protogame .prod-content').removeClass('ten')
      $('#prod-protogame .prod-content').addClass('twelve')
    } else {
      $('.hidden-prod').css('display', 'none')
      $('#prod-web .prod-category').css('display', 'grid')
      $('#prod-web .prod-content').removeClass('twelve')
      $('#prod-web .prod-content').addClass('ten')
      $('#prod-protogame .prod-category').css('display', 'grid')
      $('#prod-protogame .prod-content').removeClass('twelve')
      $('#prod-protogame .prod-content').addClass('ten')
    }
  }

  var addEvent = function (object, type, callback) {
    if (object == null || typeof object == 'undefined') return
    if (object.addEventListener) {
      object.addEventListener(type, callback, false)
    } else if (object.attachEvent) {
      object.attachEvent('on' + type, callback)
    } else {
      object['on' + type] = callback
    }
  }

  addEvent(window, 'resize', function () {
    adaptProdToWidth()
  })

  // will be usefull to know when the user scroll by itself, and then stop the auto scroll in protogame div
  var lastScrollTop = 0
  var justScrolledTop = false

  // will be called when protogame div animation start. So it's also called during closing, but not a problem for the moment due to protogames being at the bottom of the page.
  function initAdaptedScroll() {
    scrollToProtoProd()
  }

  // scroll to the protoprod div
  // can be canceled by scrolling top but not by scrolling down
  // could this be frustrating for the user ?
  function scrollToProtoProd() {
    if (justScrolledTop) justScrolledTop = false
    else if ($('#prod-protogame .rah-animating').length > 0) {
      document.getElementById('prod-protogame').scrollIntoView()
      setTimeout(() => {
        scrollToProtoProd()
      }, 100)
    }
  }

  addEvent(window, 'scroll', function () {
    if (document.documentElement.scrollTop < lastScrollTop)
      justScrolledTop = true
    lastScrollTop = document.documentElement.scrollTop
  })

  // Utilisation de useEffect pour exécuter adaptProdToWidth après le montage
  useEffect(() => {
    adaptProdToWidth()
  }, []) // [] signifie que cet effet s'exécute une seule fois, après le montage

  return (
    <div id="prod" className="row">
      <div id="hidden-prod-web" className="hidden-prod prod-row row">
        <ProdCategory big={true}>Sites web</ProdCategory>
      </div>
      <div id="prod-web" className="prod-row row">
        <ProdCategory big={false}>Sites web</ProdCategory>
        <WebProdContent />
      </div>
      <div id="hidden-prod-protogame" className="hidden-prod prod-row row">
        <ProdCategory big={true}>Prototypes de jeux</ProdCategory>
      </div>
      <div id="prod-protogame" className="prod-row row">
        <ProdCategory big={false}>Prototypes de jeux</ProdCategory>
        <PGProdContent initAdaptedScroll={initAdaptedScroll} />
      </div>
    </div>
  )
}

export default Prod
