import { useState, useEffect } from 'react'

function AddGameByIGDB({ onModifyGame }) {
  // État pour stocker l'ID IGDB saisi par l'utilisateur
  const [igdbId, setIgdbId] = useState('')
  // État pour stocker les données du jeu récupérées depuis l'API IGDB
  const [gameData, setGameData] = useState(null)
  // État pour afficher les erreurs (comme jeu déjà existant ou autre)
  const [error, setError] = useState('')
  // État pour stocker l'ID du jeu ajouté à la base de données après soumission
  const [addedGameId, setAddedGameId] = useState(null)
  // État pour suivre si le jeu est gratuit ou non
  const [isFree, setIsFree] = useState(false)
  // État pour stocker les commentaires ajoutés par l'utilisateur
  const [comments, setComments] = useState('')
  // État pour stocker les plateformes récupérées depuis la base de données
  const [platforms, setPlatforms] = useState([])
  // État pour stocker les sous-plateformes récupérées depuis la base de données
  const [subplatforms, setSubplatforms] = useState([])
  // État pour stocker les possesseurs du jeu récupérés depuis la base de données
  const [owners, setOwners] = useState([])
  // État pour stocker la plateforme sélectionnée par l'utilisateur lors de l'ajout du jeu
  const [selectedPlatform, setSelectedPlatform] = useState('')
  // État pour stocker le propriétaire sélectionné par l'utilisateur lors de l'ajout du jeu
  const [selectedOwner, setSelectedOwner] = useState('')
  // État pour stocker la sous-plateforme sélectionnée par l'utilisateur (optionnel)
  const [selectedSubplatform, setSelectedSubplatform] = useState('')
  // État pour indiquer si le jeu bénéficie de l'abonnement Playstation Plus
  const [playstationPlus, setPlaystationPlus] = useState(false)
  // État pour indiquer si le jeu a été ajouté avec succès
  const [success, setSuccess] = useState(false)
  // État pour suivre l'état de chargement pendant les requêtes (évite de soumettre plusieurs fois)
  const [loading, setLoading] = useState(false)

  // Effet pour récupérer les données (plateformes, sous-plateformes, propriétaires) depuis le backend
  useEffect(() => {
    const controller = new AbortController() // Pour pouvoir annuler les requêtes en cas de démontage du composant
    const signal = controller.signal

    async function fetchData() {
      try {
        // Récupère les plateformes depuis l'API
        const platformsResponse = await fetch('/api/pg/platforms', { signal })
        const platformsData = await platformsResponse.json()
        setPlatforms(platformsData)

        // Récupère les sous-plateformes depuis l'API
        const subplatformsResponse = await fetch('/api/pg/subplatforms', {
          signal,
        })
        const subplatformsData = await subplatformsResponse.json()
        setSubplatforms(subplatformsData)

        // Récupère les propriétaires depuis l'API
        const ownersResponse = await fetch('/api/pg/owners', { signal })
        const ownersData = await ownersResponse.json()
        setOwners(ownersData)
      } catch (err) {
        if (err.name !== 'AbortError') {
          // Si l'erreur est autre qu'une annulation de requête
          console.error(
            'Error fetching platforms, subplatforms, or owners',
            err
          )
        }
      }
    }
    fetchData()
    // Cleanup : annule les requêtes si le composant est démonté
    return () => {
      controller.abort()
    }
  }, [])

  // Fonction pour gérer le changement de l'IGDB ID renseignée
  const handleChange = (e) => {
    setIgdbId(e.target.value)
  }

  // Fonction pour récupérer les données du jeu depuis l'API IGDB
  const handleFetchGame = async (e) => {
    e.preventDefault()
    setError('')
    setGameData(null) // Réinitialise les données de jeu

    try {
      // Vérifier si le jeu existe déjà dans la base de données perso
      const gamesResponse = await fetch('/api/pg/games')
      const gamesData = await gamesResponse.json()
      const existingGame = gamesData.find((game) => game.igdb_id === igdbId)

      if (existingGame) {
        // Si le jeu existe déjà, afficher une erreur avec son ID
        setError({
          message: 'Ce jeu existe déjà dans la BDD',
          gameId: existingGame.id,
        })
      } else {
        // Si le jeu n'existe pas, le récupérer depuis l'API IGDB
        const response = await fetch(`/api/fetch-game/${igdbId}`)
        const data = await response.json()

        if (response.ok && data) {
          const coverId = data.cover
          let coverUrl = ''
          try {
            // Récupérer l'URL de la couverture du jeu via un autre endpoint
            const coverResponse = await fetch(
              `/api/fetch-cover/${coverId}?size=t_logo_med`
            )
            if (coverResponse.ok) {
              const coverData = await coverResponse.json()
              coverUrl = coverData.url || ''
            } else {
              throw new Error('Erreur lors de la récupération de la couverture')
            }
          } catch (error) {
            if (error.name !== 'AbortError') {
              console.error('Error fetching cover URL:', error)
            }
            coverUrl = '' // Utiliser une chaine vide en cas d'échec
          }

          // Ajouter l'URL de la couverture aux données du jeu
          data.coverUrl = coverUrl

          // Mettre à jour les données du jeu dans le state
          setGameData(data)
        } else if (response.status === 404) {
          setError('Jeu non trouvé')
        }
      }
    } catch (err) {
      setError('Erreur lors de la récupération des données')
      console.error(err)
    }
  }

  // Fonction pour soumettre le formulaire et ajouter le jeu à la base de données
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true) // Démarrer l'indicateur de chargement

    // Validation : s'assurer que la plateforme et le propriétaire sont sélectionnés
    if (!selectedPlatform || !selectedOwner) {
      alert('Veuillez sélectionner une plateforme et un possesseur.')
      setLoading(false) // Fin du chargement en cas d'erreur de validation
      return
    }

    try {
      // Envoie une requête pour ajouter le jeu dans la table `games`
      const response = await fetch('/api/add-game', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          igdb_id: gameData.id,
          name: gameData.name,
          is_free: isFree,
          addition_date: new Date().toISOString(),
          comments: comments,
          cover_url: gameData.coverUrl,
        }),
      })

      const result = await response.json()

      if (response.ok) {
        // Si l'ajout est réussi, ajouter également les informations dans `game_platforms`
        const gameId = result.game.id
        setAddedGameId(gameId) // On garde l'ID du jeu ajouté
        // Ajoute une possession du jeu dans la base de données
        const gamePlatformResponse = await fetch('/api/add-game-platform', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            game_id: gameId,
            possessions: [
              {
                platform_id: selectedPlatform,
                playstation_plus: playstationPlus,
                owner_id: selectedOwner,
                subplatform_id: selectedSubplatform || null, // Envoyer null si aucune sous-plateforme n'est sélectionnée
              },
            ],
          }),
        })

        const gamePlatformResult = await gamePlatformResponse.json()

        if (gamePlatformResponse.ok) {
          // Si tout est réussi, réinitialiser les états du formulaire et afficher un message de succès
          setSuccess(true)
          setIgdbId('')
          setGameData(null)
          setIsFree(false)
          setComments('')
          setSelectedPlatform('')
          setSelectedOwner('')
          setSelectedSubplatform('')
          setPlaystationPlus(false)
        } else {
          alert('Erreur: ' + gamePlatformResult.error)
        }
      } else {
        alert('Erreur: ' + result.error)
        // NOTE : CE SERAIT BIEN DE SUPPRIMER LES DONNES JUSTES ENREGISTREES DANS LA TABLE games ICI, SI LA SECONDE REQUETE A ECHOUE. A REFLEHIR.
      }
    } catch (err) {
      alert("Erreur lors de l'ajout du jeu")
      console.error(err)
    } finally {
      setLoading(false) // Arrêter l'indicateur de chargement
    }
  }

  return (
    <div>
      {!success ? (
        <>
          <form onSubmit={handleFetchGame}>
            <label>
              IGDB ID : &nbsp;
              <input
                type="number"
                value={igdbId}
                onChange={handleChange}
                required
              />
            </label>
            <button type="submit">Récupérer les Infos du Jeu</button>
          </form>
          {error && (
            <div style={{ color: 'red' }}>
              <p>{error.message}</p>
              {error.gameId && (
                <button onClick={() => onModifyGame(error.gameId)}>
                  Modifier ce jeu
                </button>
              )}
            </div>
          )}

          {gameData && (
            <form className="add-game-form" onSubmit={handleSubmit}>
              <h3>{gameData.name}</h3>
              <label>
                <input
                  type="checkbox"
                  checked={isFree}
                  onChange={(e) => setIsFree(e.target.checked)}
                />
                &nbsp;Gratuit
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={playstationPlus}
                  onChange={(e) => setPlaystationPlus(e.target.checked)}
                />
                &nbsp;Playstation Plus
              </label>
              <label>
                Propriétaire : &nbsp;
                <select
                  value={selectedOwner}
                  onChange={(e) => setSelectedOwner(e.target.value)}
                  required
                >
                  <option value="">Sélectionner un possesseur</option>
                  {owners.map((owner) => (
                    <option key={owner.id} value={owner.id}>
                      {owner.name}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Plateforme :&nbsp;
                <select
                  value={selectedPlatform}
                  onChange={(e) => setSelectedPlatform(e.target.value)}
                  required
                >
                  <option value="">Sélectionner une plateforme</option>
                  {platforms.map((platform) => (
                    <option key={platform.id} value={platform.id}>
                      {platform.name}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Sous-plateforme (optionnelle) : &nbsp;
                <select
                  value={selectedSubplatform}
                  onChange={(e) => setSelectedSubplatform(e.target.value)}
                >
                  <option value="">Sélectionner une sous-plateforme</option>
                  {subplatforms.map((subplatform) => (
                    <option key={subplatform.id} value={subplatform.id}>
                      {subplatform.name}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                <span className="text-input-name">Commentaires :</span>
                <textarea
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  placeholder="Ajoutez un commentaire"
                />
              </label>
              <button type="submit" disabled={loading}>
                {loading ? 'Ajout en cours...' : 'Ajouter le Jeu'}
              </button>
            </form>
          )}
        </>
      ) : (
        <div>
          <p>Jeu ajouté avec succès !</p>
          <button onClick={() => onModifyGame(addedGameId)}>
            Modifier ce jeu
          </button>
        </div>
      )}
    </div>
  )
}

export default AddGameByIGDB
