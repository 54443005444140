import { useState, useEffect } from 'react'
import $ from 'jquery'

function ProtoScreenshots(props) {
  const [modalDisplay, setModalDisplay] = useState('none')
  const [screenInModal, setScreenInModal] = useState(null)

  function handleSsModal(screenIndex) {
    if (window.screen.height > 710)
      setScreenInModal(props.screenshots[screenIndex])
  }

  useEffect(() => {
    if (screenInModal) setModalDisplay('block')
  }, [screenInModal])

  function horizontalScreen() {
    if (window.innerWidth > window.innerHeight) return true
    else return false
  }

  var adaptedSsModalImg = horizontalScreen()
    ? (adaptedSsModalImg = 'ss-modal-content-h')
    : (adaptedSsModalImg = 'ss-modal-content-v')

  var screenshotsModal = (
    <div id="myssModal" className="ss-modal" style={{ display: modalDisplay }}>
      <img
        className={adaptedSsModalImg}
        src={screenInModal}
        alt="Version agrandie du screenshot"
      />
    </div>
  )

  // Close the modal when clicking anywhere else.
  window.onclick = function (event) {
    if (event.target.id == 'myssModal') {
      setModalDisplay('none')
      setScreenInModal(null)
    }
  }

  // Close the modal when pressing any key.
  $(document).on('keydown', function () {
    setModalDisplay('none')
    setScreenInModal(null)
  })

  return (
    <div id="proto-screenshots">
      <br />
      <br />
      <div id={props.screenRowName + '-screen-row-1'} className="row">
        <img
          src={props.screenshots[0]}
          alt="Capture d'écran 00 du prototype"
          className="six columns"
          onClick={() => handleSsModal(0)}
        />
        <img
          src={props.screenshots[1]}
          alt="Capture d'écran 01 du prototype"
          className="six columns"
          onClick={() => handleSsModal(1)}
        />
      </div>
      <br className="screen-br" />
      <div id={props.screenRowName + '-screen-row-2'} className="row">
        <img
          src={props.screenshots[2]}
          alt="Capture d'écran 02 du prototype"
          className="six columns"
          onClick={() => handleSsModal(2)}
        />
        <img
          src={props.screenshots[3]}
          alt="Capture d'écran 03 du prototype"
          className="six columns"
          onLoad={props.trueDivAdapter}
          onClick={() => handleSsModal(3)}
        />
      </div>
      {screenshotsModal}
    </div>
  )
}

export default ProtoScreenshots
