import { useState } from 'react'
import SkillImg from '../SkillImg'
import { skillsList } from '../../datas/skillsList' // full list of my skills
import $ from 'jquery'

function Protogame_0() {
  const [modalDisplay, setModalDisplay] = useState('none')

  // Close the modal when clicking anywhere else.
  window.onclick = function (event) {
    if (event.target.id == 'myProtogameSkillsModal') {
      setModalDisplay('none')
    }
  }

  // Close the modal when pressing any key.
  $(document).on('keydown', function () {
    setModalDisplay('none')
  })

  var PGModal = (
    <div
      id="myProtogameSkillsModal"
      className="protogame-skills-modal"
      style={{ display: modalDisplay }}
    >
      <div className="protogame-skills-modal-content">
        <span
          className="close-protogame-skills-modal"
          onClick={() => setModalDisplay('none')}
        >
          &times;
        </span>
        Mes diverses compétences en programmation de jeux vidéo :<br />
        <div className="modalSkillsDiv">
          {skillsList.map(({ logo, name, informations, usedInProtogame }) =>
            usedInProtogame ? (
              <SkillImg
                key={'_skill_' + name}
                skillsrc={logo}
                skillname={name}
                skilltitle={informations}
              />
            ) : null
          )}
        </div>
      </div>
    </div>
  )

  return (
    <div>
      <p>
        Passionné par le game design et la programmation et fort de trois ans
        d&apos;études dans le développement, je passe beaucoup de temps à
        m&apos;entrainer ou à prototyper diverses idées en utilisant{' '}
        <span className="skills-list" onClick={() => setModalDisplay('block')}>
          {' '}
          différents moyens
        </span>
        . En voici quelques-unes.
      </p>
      {PGModal}
    </div>
  )
}

export default Protogame_0
