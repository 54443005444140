// This is the all prods category part, including websites and game prototypes.
// the big argument is useful to know what size (ten or twelves columns) the category takes.
function SkillImg({
  skillsrc,
  skillname,
  skilltitle,
  onmouseenter,
  onmouseleave,
}) {
  return (
    <img
      src={skillsrc}
      className="skills"
      alt={`logo ${skillname}`}
      title={skilltitle}
      onMouseEnter={onmouseenter}
      onMouseLeave={onmouseleave}
      draggable={false}
    />
  )
}

export default SkillImg
