import { useState, useEffect } from 'react'

function ModifyGame({ gameId }) {
  const [platforms, setPlatforms] = useState([])
  const [subplatforms, setSubplatforms] = useState([])
  const [owners, setOwners] = useState([])
  const [formData, setFormData] = useState({
    name: '',
    igdb_id: '',
    cover_url: '',
    comments: '',
    platforms: [], // Tableau d'objets avec platform_id, subplatform_id, owner_id, and playstation_plus
  })
  const [loading, setLoading] = useState(true)
  const [newPossession, setNewPossession] = useState([]) // Pour ajouter de nouvelles possessions
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    let isMounted = true

    async function fetchData() {
      try {
        // Récuperation des données du jeu
        const gameResponse = await fetch(`/api/pg/games?id=${gameId}`)
        const gameData = await gameResponse.json()

        // Récuperation des données de plateformes
        const platformsResponse = await fetch('/api/pg/platforms')
        const platformsData = await platformsResponse.json()
        setPlatforms(platformsData)

        // Récuperation des données de sous-plateformes
        const subplatformsResponse = await fetch('/api/pg/subplatforms')
        const subplatformsData = await subplatformsResponse.json()
        setSubplatforms(subplatformsData)

        // Récuperation des données de propriétaires
        const ownersResponse = await fetch('/api/pg/owners')
        const ownersData = await ownersResponse.json()
        setOwners(ownersData)

        // Récuperation des données de possessions
        const gamesPlatformsResponse = await fetch('/api/pg/game_platforms')
        const gamesPlatformsData = await gamesPlatformsResponse.json()

        // Rempli les données de formulaire avec les données du jeu
        if (isMounted) {
          const filteredPlatforms = gamesPlatformsData
            ? gamesPlatformsData.filter((gp) => gp.game_id === gameId)
            : []

          // Mise à jour de formData
          setFormData({
            name: gameData.name || '',
            igdb_id: gameData.igdb_id || undefined,
            is_free: gameData.is_free || false,
            cover_url: gameData.cover_url || '',
            comments: gameData.comments || '',
            platforms: filteredPlatforms,
          })

          // Mise à jour de newPossession
          setNewPossession(filteredPlatforms)
        }
      } catch (error) {
        console.error('Erreur de récupération des données')
      } finally {
        if (isMounted) {
          setLoading(false)
        }
      }
    }

    fetchData()

    return () => {
      isMounted = false
    }
  }, [gameId])

  // Récupère les données de la couverture à l'aide de l'IGDB ID
  useEffect(() => {
    const fetchCoverUrl = async (igdbId) => {
      if (!igdbId) return

      try {
        const response = await fetch(`/api/fetch-game/${igdbId}?full=false`)
        if (response.ok) {
          const data = await response.json()

          const coverId = data.cover
          let coverUrl = ''

          // Tenter de récupérer l'URL de la couverture
          try {
            const coverResponse = await fetch(
              `/api/fetch-cover/${coverId}?size=t_logo_med`
            )
            if (coverResponse.ok) {
              const coverData = await coverResponse.json()
              coverUrl = coverData.url || ''
            } else {
              throw new Error('Erreur lors de la récupération de la couverture')
            }
          } catch (error) {
            if (error.name !== 'AbortError') {
              console.error('Error fetching cover URL:', error)
            }
            coverUrl = '' // En cas d'erreur, utiliser une chaîne vide
          }

          // Mise à jour de formData avec l'URL de couverture
          setFormData((prevFormData) => ({
            ...prevFormData,
            cover_url: coverUrl || '',
          }))
        } else {
          throw new Error('Jeu IGDB non trouvé')
        }
      } catch (error) {
        console.error('Erreur lors de la récupération de la couverture:', error)
      }
    }

    if (formData.igdb_id) {
      fetchCoverUrl(formData.igdb_id)
    }
  }, [formData.igdb_id])

  // Sert pour les possessions
  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const updatedPossessions = [...newPossession]

    if (updatedPossessions[index]) {
      updatedPossessions[index] = {
        ...updatedPossessions[index],
        [name]: value,
      }
      setNewPossession(updatedPossessions)
    }
  }

  const handleCheckboxChange = (e, index) => {
    const { name, checked } = e.target

    if (index !== undefined) {
      const updatedPossessions = [...newPossession]
      if (updatedPossessions[index]) {
        updatedPossessions[index] = {
          ...updatedPossessions[index],
          [name]: checked,
        }
        setNewPossession(updatedPossessions)
      }
    } else {
      setFormData({ ...formData, [name]: checked })
    }
  }

  const handleAddPossession = () => {
    setNewPossession([
      ...newPossession,
      {
        platform_id: null,
        subplatform_id: null,
        owner_id: null,
        playstation_plus: false,
      },
    ])
  }

  const handleRemovePossession = (index) => {
    const updatedPossessions = newPossession.filter((_, i) => i !== index)
    setNewPossession(updatedPossessions)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (newPossession.length === 0) {
      alert('Veuillez ajouter au moins une possession.')
      return
    }

    try {
      // Mise à jour ou ajout du jeu
      const gameResponse = await fetch('/api/add-game', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: gameId, ...formData }),
      })

      if (!gameResponse.ok) {
        throw new Error('Erreur lors de la modification du jeu.')
      }

      const gameData = await gameResponse.json()

      // Mise à jour ou ajout des possessions
      const platformsResponse = await fetch('/api/add-game-platform', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          game_id: gameData.game.id,
          possessions: newPossession,
        }),
      })

      if (!platformsResponse.ok) {
        throw new Error('Erreur lors de la mise à jour des plateformes.')
      }

      setSuccess(true)
    } catch (error) {
      console.error(error)
    }
  }

  if (loading) {
    return <div>Chargement des données...</div>
  }

  return (
    <div>
      {!success ? (
        <form className="modify-game-form" onSubmit={handleSubmit}>
          <h2>Modifier le jeu</h2>

          <label>
            Nom : &nbsp;
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              required
            />
          </label>

          <label>
            IGDB ID : &nbsp;
            <input
              type="number"
              name="igdb_id"
              value={formData.igdb_id || ''}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  igdb_id: e.target.value === '' ? null : e.target.value,
                })
              }
            />
          </label>

          <label>
            Gratuit : &nbsp;
            <input
              type="checkbox"
              name="is_free"
              checked={formData.is_free}
              onChange={(e) => handleCheckboxChange(e)}
            />
          </label>

          <label>
            <span className="text-input-name">Commentaires :</span>
            <textarea
              name="comments"
              value={formData.comments}
              onChange={(e) =>
                setFormData({ ...formData, comments: e.target.value })
              }
            />
          </label>

          <label>
            Image de couverture (lien) : &nbsp;
            <input
              type="text"
              name="cover_url"
              value={formData.cover_url}
              onChange={(e) =>
                setFormData({ ...formData, cover_url: e.target.value })
              }
            />
          </label>

          <div>
            {newPossession.map((possession, index) => (
              <div key={index} className="possession-box">
                <label>
                  Plateforme : &nbsp;
                  <select
                    name="platform_id"
                    value={possession.platform_id || ''}
                    onChange={(e) => handleInputChange(e, index)}
                    required
                  >
                    <option value="">Sélectionnez une plateforme</option>
                    {platforms.map((platform) => (
                      <option key={platform.id} value={platform.id}>
                        {platform.name}
                      </option>
                    ))}
                  </select>
                </label>

                <label>
                  Sous-plateforme : &nbsp;
                  <select
                    name="subplatform_id"
                    value={possession.subplatform_id || ''}
                    onChange={(e) => handleInputChange(e, index)}
                  >
                    <option value="">Sélectionnez une sous-plateforme</option>
                    {subplatforms.map((subplatform) => (
                      <option key={subplatform.id} value={subplatform.id}>
                        {subplatform.name}
                      </option>
                    ))}
                  </select>
                </label>

                <label>
                  Possédé par : &nbsp;
                  <select
                    name="owner_id"
                    value={possession.owner_id || ''}
                    onChange={(e) => handleInputChange(e, index)}
                  >
                    <option value="">Sélectionnez un propriétaire</option>
                    {owners.map((owner) => (
                      <option key={owner.id} value={owner.id}>
                        {owner.name}
                      </option>
                    ))}
                  </select>
                </label>

                <label>
                  PlayStation Plus : &nbsp;
                  <input
                    type="checkbox"
                    name="playstation_plus"
                    checked={possession.playstation_plus || false}
                    onChange={(e) => handleCheckboxChange(e, index)}
                  />
                </label>

                <button
                  type="button"
                  className="delete-possession"
                  onClick={() => handleRemovePossession(index)}
                >
                  Supprimer
                </button>
              </div>
            ))}

            <button
              type="button"
              className="add-possession"
              onClick={handleAddPossession}
            >
              Ajouter une possession
            </button>
          </div>

          <button type="submit" className="save-button">
            Enregistrer
          </button>
        </form>
      ) : (
        <div className="success-message">
          <h2>Modification réussie !</h2>
        </div>
      )}
    </div>
  )
}

export default ModifyGame
