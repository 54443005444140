import { useState, useCallback } from 'react'
import ProtoScreenshots from '../ProtoScreenshots'
import CantPlay from '../CantPlay'
import CantPlayTemp from '../CantPlayTemp'
import SkillImg from '../SkillImg'
import unity from '../../assets/images/skills/unity.svg'
import csharp from '../../assets/images/skills/csharp.svg'
import unity_uncolored from '../../assets/images/skills/unity-uncolored.svg'
import csharp_uncolored from '../../assets/images/skills/csharp-uncolored.svg'
import screenshot_0 from '../../assets/images/screenshots/protogame/bubble/bubble_0.png'
import screenshot_1 from '../../assets/images/screenshots/protogame/bubble/bubble_1.png'
import screenshot_2 from '../../assets/images/screenshots/protogame/bubble/bubble_2.png'
import screenshot_3 from '../../assets/images/screenshots/protogame/bubble/bubble_3.png'

function Protogame_4(props) {
  const [skillMouseIsOn, setSkillMouseIsOn] = useState(null)
  const screenshots = [screenshot_0, screenshot_1, screenshot_2, screenshot_3]

  const [/* proto, */ setProto] = useState(
    <button id="play-button" onClick={launchProto}>
      Lancer le prototype
    </button>
  )

  function launchProto() {
    setProto(protoToLoad)
  }

  const protoToLoad = (
    <div>
      {/*
      <div id="bubble" className="game" tabIndex="1">
        <iframe
          src="/Protogames/Protogame-4/vanillabuild.html"
          title="some bubble thing"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          scrolling="no"
        ></iframe>
      </div>
      */}
      <CantPlay />
    </div>
  )

  const trueDivAdapter = useCallback(() => {
    props.trueDivAdapter()
  }, [props])

  return (
    <div>
      {/*proto*/}
      <CantPlayTemp />
      <br />
      <ProtoScreenshots
        screenshots={screenshots}
        screenRowName="bubble"
        trueDivAdapter={trueDivAdapter}
      />
      <br />
      <p>
        Ce jeu est simplement une tentative de{' '}
        <span className="important">reproduction</span> d&apos;un{' '}
        <a
          href="https://youtu.be/fHAsaCO0lEs?t=2685"
          target="_blank"
          rel="noreferrer"
        >
          minigame
        </a>{' '}
        de <span className="italic-name">Muppets Party Cruise</span> (malgré
        quelques détails modifiés par rapport à l&apos;original).
        <br />
        Le concept comme la musique (composée par{' '}
        <span className="italic-name">Chris Tilton</span>) ne
        m&apos;appartiennent donc en aucun cas et ne sont là que prétextes à
        m&apos;entrainer et à partager un concept intéressant.
        <br />
        Arriverez-vous à dépasser la barre des 1000 points !?
      </p>
      <div className="controls">
        <span className="important">CONTROLES</span> : <br />-
        <span className="important">Touches directionnelles</span> pour déplacer
        le curseur
        <br />-<span className="important">Espace</span> pour effectuer un
        switch entre les boules sélectionnées, ou pour recommencer en fin de
        partie
        <br />-<span className="important">Ctrl</span> pour un retour direct à
        la colonne inférieure
        <br />-<span className="important">R</span> pour recommencer la partie
        en cours
        <br />
      </div>
      <br />

      {skillMouseIsOn === 'unity' ? (
        <SkillImg
          skillsrc={unity}
          skilltitle="Ce prototype a été créé sous Unity"
          skillname="unity"
          onmouseleave={() => setSkillMouseIsOn(null)}
        />
      ) : (
        <SkillImg
          skillsrc={unity_uncolored}
          skilltitle="Ce prototype a été créé sous Unity"
          skillname="unity"
          onmouseenter={() => setSkillMouseIsOn('unity')}
        />
      )}

      {skillMouseIsOn === 'csharp' ? (
        <SkillImg
          skillsrc={csharp}
          skilltitle="Les scripts ont été écrits en C#"
          skillname="csharp"
          onmouseleave={() => setSkillMouseIsOn(null)}
        />
      ) : (
        <SkillImg
          skillsrc={csharp_uncolored}
          skilltitle="Les scripts ont été écrits en C#"
          skillname="csharp"
          onmouseenter={() => setSkillMouseIsOn('csharp')}
        />
      )}
    </div>
  )
}

export default Protogame_4
