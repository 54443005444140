import { useState, useEffect, useRef } from 'react'
import GameInfoModal from './GameInfoModal'
import default_cover from '../../assets/images/collection/default_cover.png'

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

function checkImageExists(url) {
  return new Promise((resolve) => {
    const img = new Image()
    img.src = url

    img.onload = () => resolve(true) // L'image est valide
    img.onerror = () => resolve(false) // L'image est invalide ou n'existe plus
  })
}

function getPlatformsForGame(gameId, gamesPlatforms, platforms, subplatforms) {
  const gamePlatformLinks = gamesPlatforms.filter((gp) => gp.game_id === gameId)

  return gamePlatformLinks.map((gamePlatformLink) => {
    const platform = platforms.find(
      (p) => p.id === gamePlatformLink.platform_id
    )
    const associatedSubplatforms = gamePlatformLink.subplatform_id
      ? subplatforms.filter((sp) => sp.id === gamePlatformLink.subplatform_id)
      : []

    return {
      ...platform,
      subplatforms: associatedSubplatforms,
      isOnlyPSPlus: gamePlatformLink.playstation_plus,
    }
  })
}

function CollectionContentGames({
  games,
  gamesPlatforms,
  platforms,
  subplatforms,
  owners,
  onModifyGame,
}) {
  const [selectedGame, setSelectedGame] = useState(null)
  const [gamesData, setGamesData] = useState({})
  const [coverUrls, setCoverUrls] = useState({})
  const abortControllerRef = useRef(null)

  const handleGameClick = (game) => {
    setSelectedGame(game)
  }

  const handleCloseModal = () => {
    setSelectedGame(null)
  }

  async function fetchGameDataWithRetry(
    igdbId,
    signal,
    retries = 5,
    delayMs = 1000
  ) {
    let attempt = 0

    while (attempt < retries) {
      try {
        const response = await fetch(`/api/fetch-game/${igdbId}?full=false`, {
          signal,
        })

        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données IGDB')
        }

        return await response.json()
      } catch (error) {
        if (error.name === 'AbortError') {
          // Si l'erreur est liée à l'annulation de la requête, on arrête immédiatement
          // console.error('Requête annulée:', error)
          return null
        }
        if (attempt < retries - 1) {
          await delay(delayMs) // Attendre un certain temps avant la prochaine tentative
        } else {
          console.error('Toutes les tentatives ont échoué:', error)
          return null
        }
      }
      attempt++
    }
  }

  const fetchAllData = async (gameList, signal) => {
    const gamesDataTemp = {}
    const coverUrlsTemp = {}

    const dataPromises = gameList.map(async (game) => {
      if (game.igdb_id) {
        const gameData = await fetchGameDataWithRetry(game.igdb_id, signal)

        if (gameData) {
          gamesDataTemp[game.id] = gameData
        }
      }

      // Vérifier l'existence de l'image via cover_url
      if (game.cover_url) {
        const exists = await checkImageExists(game.cover_url)
        coverUrlsTemp[game.id] = exists ? game.cover_url : default_cover
      } else {
        coverUrlsTemp[game.id] = default_cover // Utiliser l'image par défaut si pas de cover_url
      }
    })

    await Promise.all(dataPromises)

    setGamesData(gamesDataTemp)
    setCoverUrls(coverUrlsTemp)
  }

  useEffect(() => {
    // Annuler les requêtes précédentes
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }

    abortControllerRef.current = new AbortController()
    const { signal } = abortControllerRef.current

    // Charger les données de jeux et les couvertures
    fetchAllData(games, signal)

    return () => {
      // Annuler les requêtes en cours si le composant se démonte
      abortControllerRef.current.abort()
    }
  }, [games])

  return (
    <div className="collection-games-grid">
      {games.map((game) => {
        const gameData = gamesData[game.id]
        const coverUrl = coverUrls[game.id] || default_cover

        return (
          <div key={game.id} className="game-item">
            <h2
              className="game-title"
              onClick={() => handleGameClick(game)}
              style={{ pointerEvents: 'auto' }}
            >
              {gameData ? gameData.name : game.name}
            </h2>
            <img
              src={coverUrl}
              alt={gameData ? gameData.name : 'Cover'}
              className="game-cover"
              onClick={() => handleGameClick(game)}
              style={{ pointerEvents: 'auto' }}
            />
          </div>
        )
      })}
      {selectedGame && (
        <GameInfoModal
          game={selectedGame} // Passer le jeu avec les données combinées
          onClose={handleCloseModal}
          platforms={getPlatformsForGame(
            selectedGame.id,
            gamesPlatforms,
            platforms,
            subplatforms
          )}
          gamesPlatforms={gamesPlatforms}
          owners={owners}
          onModifyGame={onModifyGame}
        />
      )}
    </div>
  )
}

export default CollectionContentGames
