import { useState, useEffect, useMemo } from 'react'
import CollectionContentOptions from './CollectionContentOptions'
import CollectionContentGames from './CollectionContentGames'
import CollectionContentPages from './CollectionContentPages'
import ModifyGame from './ModifyGame'

function CollectionContent() {
  const [games, setGames] = useState([])
  const [platforms, setPlatforms] = useState([])
  const [selectedPlatforms, setSelectedPlatforms] = useState([])
  const [subplatforms, setSubplatforms] = useState([])
  const [owners, setOwners] = useState([])
  const [gamesPlatforms, setGamesPlatforms] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [includeFreeGames, setIncludeFreeGames] = useState(false)
  const [includePSPlusGames, setIncludePSPlusGames] = useState(true)
  const [sortOption, setSortOption] = useState('latest')
  const [selectedOwners, setSelectedOwners] = useState([])
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [gameToModify, setGameToModify] = useState(null)
  const [showModifyGame, setShowModifyGame] = useState(false)
  const gamesPerPage = 9
  const resetPage = () => setCurrentPage(1)

  // Fonction pour sauvegarder les données dans le localStorage
  const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
  }

  // Fonction pour récupérer les données du localStorage
  const loadFromLocalStorage = (key) => {
    const data = localStorage.getItem(key)
    return data ? JSON.parse(data) : null
  }

  // Fonction pour recharger les données à partir de l'API
  const reloadDataFromAPI = async () => {
    console.log("chargement depuis l'API ...")
    setLoading(true)
    try {
      const gamesResponse = await fetch('/api/pg/games')
      const gamesData = await gamesResponse.json()
      setGames(gamesData)
      saveToLocalStorage('games', gamesData)

      const platformsResponse = await fetch('/api/pg/platforms')
      const platformsData = await platformsResponse.json()
      setPlatforms(platformsData)
      saveToLocalStorage('platforms', platformsData)

      const subplatformsResponse = await fetch('/api/pg/subplatforms')
      const subplatformsData = await subplatformsResponse.json()
      setSubplatforms(subplatformsData)
      saveToLocalStorage('subplatforms', subplatformsData)

      const ownersResponse = await fetch('/api/pg/owners')
      const ownersData = await ownersResponse.json()
      setOwners(ownersData)
      saveToLocalStorage('owners', ownersData)

      const gamesPlatformsResponse = await fetch('/api/pg/game_platforms')
      const gamesPlatformsData = await gamesPlatformsResponse.json()
      setGamesPlatforms(gamesPlatformsData)
      saveToLocalStorage('gamesPlatforms', gamesPlatformsData)

      // Initialiser les sélections par défaut après chargement
      setSelectedOwners(ownersData.map((owner) => owner.id))
      setSelectedPlatforms(platformsData.map((platform) => platform.id))
    } catch (error) {
      console.error('Erreur lors de la récupération des données ...')
    } finally {
      setLoading(false)
    }
  }

  // Charger les données à partir du localStorage ou de l'API au premier montage
  useEffect(() => {
    const storedGames = loadFromLocalStorage('games')
    const storedPlatforms = loadFromLocalStorage('platforms')
    const storedSubplatforms = loadFromLocalStorage('subplatforms')
    const storedOwners = loadFromLocalStorage('owners')
    const storedGamesPlatforms = loadFromLocalStorage('gamesPlatforms')

    if (
      storedGames &&
      storedPlatforms &&
      storedSubplatforms &&
      storedOwners &&
      storedGamesPlatforms
    ) {
      // Charger les données depuis localStorage
      setGames(storedGames)
      setPlatforms(storedPlatforms)
      setSubplatforms(storedSubplatforms)
      setOwners(storedOwners)
      setGamesPlatforms(storedGamesPlatforms)

      setSelectedOwners(storedOwners.map((owner) => owner.id))
      setSelectedPlatforms(storedPlatforms.map((platform) => platform.id))
      setLoading(false)
    } else {
      // Recharger les données depuis l'API si non disponible dans le localStorage
      reloadDataFromAPI()
      console.log('Chargement initiale des données')
    }
  }, [])

  const filteredGames = useMemo(() => {
    return games.filter((game) => {
      let shouldExclude = false

      if (!includeFreeGames && game.is_free) {
        shouldExclude = true
      }

      if (
        searchTerm &&
        !game.name.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        shouldExclude = true
      }

      const gamePlatformLinks = gamesPlatforms.filter(
        (gp) => gp.game_id === game.id
      )
      const selectedPlatformLinks = gamePlatformLinks.filter((gp) =>
        selectedPlatforms.includes(gp.platform_id)
      )

      if (!includePSPlusGames) {
        if (selectedPlatformLinks.length > 0) {
          const allSelectedArePSPlus = selectedPlatformLinks.every(
            (gp) => gp.playstation_plus
          )
          if (allSelectedArePSPlus) {
            shouldExclude = true
          }
        }
      }

      const hasSelectedOwner = selectedPlatformLinks.some((gp) =>
        selectedOwners.includes(gp.owner_id)
      )
      if (!hasSelectedOwner) {
        shouldExclude = true
      }

      const platformsForGame = selectedPlatformLinks.map((gp) => gp.platform_id)
      const isOnSelectedPlatform = platformsForGame.length > 0

      if (!isOnSelectedPlatform) {
        shouldExclude = true
      }

      return !shouldExclude
    })
  }, [
    games,
    gamesPlatforms,
    selectedPlatforms,
    selectedOwners,
    includeFreeGames,
    includePSPlusGames,
    searchTerm,
  ])

  const sortedGames = useMemo(() => {
    return [...filteredGames].sort((a, b) => {
      if (sortOption === 'alphabetical') {
        return a.name.localeCompare(b.name)
      } else if (sortOption === 'latest') {
        return new Date(b.addition_date) - new Date(a.addition_date)
      }
      return 0
    })
  }, [filteredGames, sortOption])

  const totalPages = Math.ceil(sortedGames.length / gamesPerPage)

  const currentGames = useMemo(() => {
    const indexOfLastGame = currentPage * gamesPerPage
    const indexOfFirstGame = indexOfLastGame - gamesPerPage
    return sortedGames.slice(indexOfFirstGame, indexOfLastGame)
  }, [sortedGames, currentPage])

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  const handlePlatformChange = (platformIds, checked) => {
    if (checked) {
      // Ajouter toutes les plateformes sélectionnées
      setSelectedPlatforms((prevSelected) => [
        ...new Set([...prevSelected, ...platformIds]),
      ])
    } else {
      // Retirer toutes les plateformes désélectionnées
      setSelectedPlatforms((prevSelected) =>
        prevSelected.filter((id) => !platformIds.includes(id))
      )
    }
    resetPage()
  }

  useEffect(() => {
    if (gameToModify !== null) {
      setShowModifyGame(true)
    }
  }, [gameToModify])

  const handleModifyGame = (gameId) => {
    setGameToModify(gameId)
  }

  return (
    <div>
      {showModifyGame ? (
        <ModifyGame gameId={gameToModify} />
      ) : loading ? (
        <p>Chargement...</p>
      ) : (
        <>
          <CollectionContentOptions
            includeFreeGames={includeFreeGames}
            includePSPlusGames={includePSPlusGames}
            owners={owners}
            platforms={platforms}
            selectedOwners={selectedOwners}
            selectedPlatforms={selectedPlatforms}
            onIncludeFreeGamesChange={(value) => {
              setIncludeFreeGames(value)
              resetPage()
            }}
            onIncludePSPlusGamesChange={(value) => {
              setIncludePSPlusGames(value)
              resetPage()
            }}
            onSortChange={(value) => {
              setSortOption(value)
              resetPage()
            }}
            onOwnerChange={(ownerId, checked) => {
              setSelectedOwners(
                checked
                  ? [...selectedOwners, ownerId]
                  : selectedOwners.filter((id) => id !== ownerId)
              )
              resetPage()
            }}
            onPlatformChange={(platformIds, checked) => {
              handlePlatformChange(platformIds, checked)
              resetPage()
            }}
            onSearchChange={(value) => {
              setSearchTerm(value)
              resetPage()
            }}
          />
          <CollectionContentGames
            games={currentGames}
            gamesPlatforms={gamesPlatforms}
            platforms={platforms}
            subplatforms={subplatforms}
            owners={owners}
            onModifyGame={handleModifyGame}
          />
          <CollectionContentPages
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={paginate}
          />
          <div className="reload-button-container">
            <button
              className="reload-button"
              onClick={() => {
                reloadDataFromAPI()
                resetPage()
              }}
            >
              <span>Recharger les données plus récentes</span>
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default CollectionContent
