import { useEffect } from 'react'

function WithFavicon({ favicon, children }) {
  useEffect(() => {
    const link =
      document.querySelector("link[rel='icon']") ||
      document.createElement('link')
    link.rel = 'icon'
    link.href = favicon
    document.head.appendChild(link)

    return () => {
      document.head.removeChild(link)
    }
  }, [favicon])

  return children
}

export default WithFavicon
