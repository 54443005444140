import { useState, useCallback, useEffect } from 'react'
import ProtoScreenshots from '../ProtoScreenshots'
import SkillImg from '../SkillImg'
import CantPlay from '../CantPlay'
import CantPlayTemp from '../CantPlayTemp'
import processing from '../../assets/images/skills/processing.svg'
import js from '../../assets/images/skills/js.svg'
import processing_uncolored from '../../assets/images/skills/processing-uncolored.svg'
import js_uncolored from '../../assets/images/skills/js-uncolored.svg'
import screenshot_0 from '../../assets/images/screenshots/protogame/ibpg/ibpg_0.png'
import screenshot_1 from '../../assets/images/screenshots/protogame/ibpg/ibpg_1.png'
import screenshot_2 from '../../assets/images/screenshots/protogame/ibpg/ibpg_2.png'
import screenshot_3 from '../../assets/images/screenshots/protogame/ibpg/ibpg_3.png'

function Protogame_8(props) {
  const [skillMouseIsOn, setSkillMouseIsOn] = useState(null)
  const [/* proto, */ setProto] = useState(
    <button id="play-button" onClick={launchProto}>
      Lancer le prototype
    </button>
  )

  const screenshots = [screenshot_0, screenshot_1, screenshot_2, screenshot_3]

  const protoToLoad = (
    <div>
      <div id="ibpg" className="game" tabIndex="1">
        <iframe
          src="Protogames/Protogame-8/IBPG.html"
          title="some color switch game"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          scrolling="no"
        ></iframe>
      </div>
      <CantPlay />
    </div>
  )

  function launchProto() {
    setProto(protoToLoad)
  }

  const trueDivAdapter = useCallback(() => {
    props.trueDivAdapter()
  }, [props])

  // Utiliser useEffect pour remplacer onLoad
  useEffect(() => {
    props.ondisplay()
  }, [props])

  return (
    <div>
      {/*proto*/}
      <CantPlayTemp />
      <br />
      <ProtoScreenshots
        screenshots={screenshots}
        screenRowName="ibpg"
        trueDivAdapter={trueDivAdapter}
      />
      <br />
      <p>
        Ce puzzle game reprend le concept de{' '}
        <span className="important">switch entre les cases</span> exploré dans
        un{' '}
        <span className="internal-link" onClick={() => props.displayPG(5)}>
          autre prototype
        </span>{' '}
        basé sur{' '}
        <a
          href="https://youtu.be/fHAsaCO0lEs?t=1055"
          target="_blank"
          rel="noreferrer"
        >
          un minigame
        </a>{' '}
        de <span className="italic-name">Muppets Party Cruise</span>.
        <br />
      </p>
      <div className="controls">
        <span className="important">CONTROLES</span> : <br />
        <span className="important">Touches directionnelles</span> pour
        sélectionner un niveau débloqué (un seul par défaut)
        <br />
        <span className="important">Espace</span> pour valider le niveau
        sélectionné
        <br />
        <span className="important">Touche d&apos;échappement</span> pour
        quitter le niveau en cours
        <br />
        <span className="important">Cliquer</span> sur une tuile avec la{' '}
        <span className="important">souris</span> et en{' '}
        <span className="important">maintenant</span> le clic,{' '}
        <span className="important">déplacer</span> la souris vers une autre
        tuile avec laquelle vous souhaitez échanger la couleur
        <br />
        Vous pouvez aussi appuyer sur <span className="important">
          Ctrl
        </span>{' '}
        pour voir plus clairement la grille de jeu
        <br />
        Le reste des contrôles est expliqué en jeu
      </div>
      <br />
      <span className="important">Textes en anglais</span>
      <br />
      <br />
      {skillMouseIsOn === 'processing' ? (
        <SkillImg
          skillsrc={processing}
          skilltitle="Ce prototype a initialement été codé en Processing"
          skillname="processing"
          onmouseleave={() => setSkillMouseIsOn(null)}
        />
      ) : (
        <SkillImg
          skillsrc={processing_uncolored}
          skilltitle="Ce prototype a initialement été codé en Processing"
          skillname="processing"
          onmouseenter={() => setSkillMouseIsOn('processing')}
        />
      )}

      {skillMouseIsOn === 'js' ? (
        <SkillImg
          skillsrc={js}
          skilltitle="Ce prototype a été porté sur navigateur avec P5JS"
          skillname="js"
          onmouseleave={() => setSkillMouseIsOn(null)}
        />
      ) : (
        <SkillImg
          skillsrc={js_uncolored}
          skilltitle="Ce prototype a été porté sur navigateur avec P5JS"
          skillname="js"
          onmouseenter={() => setSkillMouseIsOn('js')}
        />
      )}
    </div>
  )
}

export default Protogame_8
