import { useState, useCallback } from 'react'
import ProtoScreenshots from '../ProtoScreenshots'
import CantPlay from '../CantPlay'
import CantPlayTemp from '../CantPlayTemp'
import SkillImg from '../SkillImg'
import construct from '../../assets/images/skills/construct.svg'
import construct_uncolored from '../../assets/images/skills/construct-uncolored.svg'
import screenshot_0 from '../../assets/images/screenshots/protogame/gravity/gravity_0.png'
import screenshot_1 from '../../assets/images/screenshots/protogame/gravity/gravity_1.png'
import screenshot_2 from '../../assets/images/screenshots/protogame/gravity/gravity_2.png'
import screenshot_3 from '../../assets/images/screenshots/protogame/gravity/gravity_3.png'

function Protogame_3(props) {
  const [skillMouseIsOn, setSkillMouseIsOn] = useState(null)
  const screenshots = [screenshot_0, screenshot_1, screenshot_2, screenshot_3]

  const [/* proto, */ setProto] = useState(
    <button id="play-button" onClick={launchProto}>
      Lancer le prototype
    </button>
  )

  function launchProto() {
    setProto(protoToLoad)
  }

  const protoToLoad = (
    <div>
      {/*
      <div id="construct-g" className="game">
        <iframe
          src="/Protogames/Protogame-3"
          title="some game"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        ></iframe>
      </div>
      */}
      <CantPlay />
    </div>
  )

  const trueDivAdapter = useCallback(() => {
    props.trueDivAdapter()
  }, [props])

  return (
    <div>
      {/*proto*/}
      <CantPlayTemp />
      <br />
      <ProtoScreenshots
        screenshots={screenshots}
        screenRowName="gravity"
        trueDivAdapter={trueDivAdapter}
      />
      <br />
      <p>
        Prototype de puzzle-game basé sur{' '}
        <span className="important">l&apos;inversion de gravité</span>
        <br />
        Ce vieux projet m&apos;a permis d&apos;apprendre sur certaines erreurs
        de level-design à éviter (en l&apos;état certains niveaux sont beaucoup
        plus réussis que d&apos;autres).
      </p>
      <div className="controls">
        <span className="important">CONTROLES</span> : <br />-
        <span className="important">Touches directionnelles</span> pour
        sélectionner un niveau débloqué (un seul par défaut)
        <br />-<span className="important">Espace</span> ou{' '}
        <span className="important">Entrée</span> pour valider le niveau
        sélectionné
        <br />
        -Le reste des contrôles est expliqué en jeu
      </div>
      <br />
      {skillMouseIsOn === 'construct' ? (
        <SkillImg
          skillsrc={construct}
          skilltitle="Ce prototype a été créé sous Construct 2"
          skillname="construct"
          onmouseleave={() => setSkillMouseIsOn(null)}
        />
      ) : (
        <SkillImg
          skillsrc={construct_uncolored}
          skilltitle="Ce prototype a été créé sous Construct 2"
          skillname="construct"
          onmouseenter={() => setSkillMouseIsOn('construct')}
        />
      )}
    </div>
  )
}

export default Protogame_3
